import { $api } from '@shared/api'

import { type GroupsListQuery } from './types'

const useGroupsListApi = (args: GroupsListQuery) => {
  return $api.useQuery('get', `/groups/`, {
    params: {
      query: {
        ...args,
      },
    },
  })
}

export { useGroupsListApi }
