import { EuiButtonIcon, EuiPopover } from '@elastic/eui'
import { ACTION } from '@shared/config'
import { useToggle } from '@shared/hooks'
import React from 'react'
import { Children, cloneElement, type PropsWithChildren } from 'react'

const TableContextMenu: ReactFc<PropsWithChildren> = ({ children }) => {
  const [isMenuOpen, toggleMenu] = useToggle()

  const modifiedChildren = Children.map(children, (child) => {
    if (!React.isValidElement(child)) {
      return child
    }

    return cloneElement(child, {
      ...child.props,
      closeContextMenu: toggleMenu,
    })
  })

  return (
    <EuiPopover
      anchorPosition="leftCenter"
      button={
        <EuiButtonIcon
          iconType="boxesVertical"
          color="text"
          onClick={toggleMenu}
          aria-label={ACTION.EDIT}
        />
      }
      isOpen={isMenuOpen}
      closePopover={toggleMenu}
      panelPaddingSize="none"
    >
      {modifiedChildren}
    </EuiPopover>
  )
}

export { TableContextMenu }
