import { EuiFlexGroup, EuiFlexItem, EuiHorizontalRule, EuiPanel } from '@elastic/eui'
import { Fragment } from 'react'

import { InfoTooltip } from '../info-tooltip'
import { type InfoPageRowProps } from './types'

const InfoPageRow: ReactFc<InfoPageRowProps> = ({ label, field, tooltip }) => {
  return (
    <Fragment key={label}>
      <EuiPanel paddingSize="m" color="transparent" borderRadius="none" hasShadow={false}>
        <EuiFlexGroup alignItems="center" gutterSize="s" key={label}>
          <EuiFlexItem grow={1}>
            {tooltip ? (
              <InfoTooltip tooltip={tooltip} color="GrayText">
                {label}
              </InfoTooltip>
            ) : (
              label
            )}
          </EuiFlexItem>

          <EuiFlexItem grow={3}>{field}</EuiFlexItem>
        </EuiFlexGroup>
      </EuiPanel>
      <EuiHorizontalRule margin="none" />
    </Fragment>
  )
}

export { InfoPageRow }
