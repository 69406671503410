import { EuiFlexGroup, EuiPanel, EuiSkeletonRectangle } from '@elastic/eui'
import { renderMultiple } from '@shared/lib'

import { useStyles } from '../styles'
import { getStyles } from './styles'

const LongCardsListSkeleton = () => {
  const styles = useStyles(getStyles)

  const LongCardSkeleton: ReactFc = () => {
    return <EuiSkeletonRectangle css={styles.skeletonCard} />
  }

  return (
    <EuiPanel borderRadius="none" paddingSize="m">
      <EuiFlexGroup direction="column" gutterSize="s">
        {renderMultiple(LongCardSkeleton, 8)}
      </EuiFlexGroup>
    </EuiPanel>
  )
}

export { LongCardsListSkeleton }
