import { EuiText } from '@elastic/eui'
import { API_ATTRIBUTE_TYPE } from '@shared/api'
import { attributeTypeEnumValues } from '@shared/api/openapi-types'

import { ATTRIBUTE_TYPE_HELP } from './attribute-type-help'
import { ATTRYBUTE_TYPE_MAP } from './attribute-type-map'

const attributeTypeOptions = attributeTypeEnumValues
  .map((key) => {
    const inputDisplay = ATTRYBUTE_TYPE_MAP[key]

    return {
      value: key,
      inputDisplay,
      dropdownDisplay: (
        <>
          {inputDisplay}
          <EuiText size="xs" color="subdued">
            {ATTRIBUTE_TYPE_HELP[key].DESCRIPTION}
          </EuiText>
        </>
      ),
    }
  })
  //TODO: Пока не появится на бекенде
  .filter(({ value }) => {
    return value !== API_ATTRIBUTE_TYPE.NESTED_LIST
  })

export { attributeTypeOptions }
