import { type EuiSelectableProps } from '@elastic/eui'
import { useRouterParams } from '@shared/hooks'
import _ from 'lodash'
import { useEffect, useState } from 'react'

import {
  type FilterPopoverOptionData,
  type FilterPopoverOptions,
  type UseSelectableFilterArgs,
} from '../types'

const useFilterPopover = ({ param, options, isSingleSelect = false }: UseSelectableFilterArgs) => {
  const { deleteParam, getParamValues, removeParamValue, addParamValue, setParam } =
    useRouterParams()

  const [popoverOptions, setPopoverOptions] = useState(options)
  const checkedItems = getParamValues(param)

  // Синхронизация popoverOptions с параметрами из URL
  useEffect(() => {
    const paramOptions = popoverOptions?.map((option) => {
      return {
        ...option,
        checked: checkedItems.includes(option.data.id.toString()) ? 'on' : undefined,
      }
    }) as FilterPopoverOptions

    if (!_.isEqual(paramOptions, popoverOptions)) {
      setPopoverOptions(paramOptions)
    }
  }, [checkedItems])

  // Изменение в списке фильтров
  const handleFilterChange: EuiSelectableProps<FilterPopoverOptionData>['onChange'] = (
    updatedOptions,
    _event,
    clickedOption,
  ) => {
    if (isSingleSelect) {
      setParam(param, clickedOption.data.id)

      return
    }

    setPopoverOptions(updatedOptions)

    if (clickedOption.checked) {
      addParamValue(param, clickedOption.data.id)
    } else {
      removeParamValue(param, clickedOption.data.id)
    }
  }

  // Очистка фильтров
  const handleFilterReset = () => {
    const uncheckedOptions = popoverOptions?.map(({ label, data: { id } }) => {
      return { label, data: { id } }
    })

    setPopoverOptions(uncheckedOptions)

    deleteParam(param)
  }

  // Подсчёт количества фильтров и активных фильтров
  const numFilters = popoverOptions?.filter((item) => {
    return item.checked !== 'off'
  }).length

  const hasActiveFilters = !!popoverOptions?.find((item) => {
    return item.checked === 'on'
  })

  const numActiveFilters = popoverOptions?.filter((item) => {
    return item.checked === 'on'
  }).length

  return {
    numFilters,
    hasActiveFilters,
    numActiveFilters,
    popoverOptions,
    handleFilterChange,
    handleFilterReset,
  }
}

export { useFilterPopover }
