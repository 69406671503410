import { AUTOMATIC_TASK_NAME, type AUTOMATIC_TASK_TYPE } from '@shared/config'

const getAutomaticTaskTypeOptions = () => {
  return Object.keys(AUTOMATIC_TASK_NAME).map((key) => {
    return {
      value: parseInt(key),
      inputDisplay: AUTOMATIC_TASK_NAME?.[parseInt(key) as AUTOMATIC_TASK_TYPE],
    }
  })
}

export { getAutomaticTaskTypeOptions }
