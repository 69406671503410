import { type EuiThemeComputed } from '@elastic/eui'
import { css } from '@emotion/react'
import { type GetStyles } from '@shared/ui/styles'

export const getStyles: GetStyles = (euiTheme: EuiThemeComputed) => {
  return {
    name: css`
      height: ${euiTheme.size.xl};
    `,
    required: css`
      margin-left: -${euiTheme.size.base};
    `,
  }
}
