import { type EuiContextMenuPanelDescriptor } from '@elastic/eui'
import { useImportTypesListApi } from '@entities/import-files'
import { AddImportFile } from '@features/import-files'
import { ACTION } from '@shared/config'
import { useDialog } from '@shared/hooks'
import { ContextMenu } from '@shared/ui/context-menu'

import { type ImportType } from '../types'
import { DownloadTemplateButton } from './download-template-button'

const getTemplatePanels = (importTypes?: ImportType[]) => {
  if (!importTypes) {
    return []
  }

  const result = importTypes.map(({ name, value }) => {
    return {
      renderItem: () => {
        return <DownloadTemplateButton name={name} type={value} />
      },
    }
  })

  return result
}

const ImportFilesButton = () => {
  const { data: importTypes } = useImportTypesListApi()

  const {
    isOpen: isAddImportFileOpen,
    handleOpenDialog: showAddImportFile,
    handleCloseDialog: closeAddImportFile,
  } = useDialog()

  const panels: EuiContextMenuPanelDescriptor[] = [
    {
      id: 0,
      items: [
        {
          name: ACTION.IMPORT,
          icon: 'share',
          onClick: showAddImportFile,
        },
        {
          name: ACTION.DOWNLOAD_TEMPLATE,
          icon: 'download',
          panel: 1,
        },
      ],
    },
    {
      id: 1,
      items: getTemplatePanels(importTypes as ImportType[]),
    },
  ]

  return (
    <>
      <ContextMenu panels={panels} menuButtonText={ACTION.ADD_FILE} startIcon="plusInCircle" />
      <AddImportFile isOpen={isAddImportFileOpen} onClose={closeAddImportFile} />
    </>
  )
}

export { ImportFilesButton }
