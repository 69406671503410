import { EuiFlexGroup, EuiPanel, EuiText } from '@elastic/eui'
import { useImportFilesListApi, useImportTypesListApi } from '@entities/import-files'
import { MESSAGE } from '@shared/config'
import { useRouterParams } from '@shared/hooks'
import { LongCardsListSkeleton, Pagination, usePagination } from '@shared/ui'

import { ImportFilesCard } from './import-files-card'
import { type ImportType } from './types'

const ImportFilesList: ReactFc = () => {
  const { params } = useRouterParams()

  const { data: filesData, isLoading: isFilesLoading } = useImportFilesListApi({
    queryArgs: params,
    refetchInterval: 30000,
  })

  const { data: importTypes } = useImportTypesListApi()

  const files = filesData?.results

  const paginationProps = usePagination(filesData?.count)

  if (isFilesLoading) {
    return <LongCardsListSkeleton />
  }

  return (
    <>
      <EuiPanel borderRadius="none" color="transparent">
        <EuiFlexGroup direction="column" gutterSize="m">
          {files?.map((file) => {
            return (
              <ImportFilesCard
                key={file.uuid}
                importTypes={(importTypes || []) as ImportType[]}
                {...file}
              />
            )
          })}

          {!files?.length && (
            <EuiPanel>
              <EuiText textAlign="center">{MESSAGE.EMPTY_FILES_LIST}</EuiText>
            </EuiPanel>
          )}
        </EuiFlexGroup>
      </EuiPanel>

      <Pagination {...paginationProps} />
    </>
  )
}

export { ImportFilesList }
