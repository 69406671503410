import { EuiButton, EuiFlexGroup, EuiPopover } from '@elastic/eui'
import { ACTION } from '@shared/config'
import { useDialog } from '@shared/hooks'

import { getCronTemplates } from './helpers'
import { type CrontabSchedule, type CronTemplatesProps } from './types'

const CronTemplates: ReactFc<CronTemplatesProps> = ({ onChange }) => {
  const { isOpen, handleOpenDialog, handleCloseDialog } = useDialog()

  const templates = getCronTemplates()

  const getClickHandler = (template: Required<CrontabSchedule>) => {
    return () => {
      onChange(template)
      handleCloseDialog()
    }
  }

  const handleTemplateButtonClick = () => {
    if (isOpen) {
      handleCloseDialog()

      return
    }

    handleOpenDialog()
  }

  const button = (
    <EuiButton onClick={handleTemplateButtonClick} size="s" fullWidth>
      {ACTION.SELECT_TEMPLATE}
    </EuiButton>
  )

  return (
    <>
      <EuiPopover display="block" button={button} isOpen={isOpen} closePopover={handleCloseDialog}>
        <EuiFlexGroup direction="column" gutterSize="s">
          <EuiFlexGroup gutterSize="s">
            <EuiButton size="s" onClick={getClickHandler(templates.hourly)}>
              {ACTION.HOURLY}
            </EuiButton>
            <EuiButton size="s" onClick={getClickHandler(templates.daily)}>
              {ACTION.DAILY}
            </EuiButton>
            <EuiButton size="s" onClick={getClickHandler(templates.weekly)}>
              {ACTION.WEEKLY}
            </EuiButton>
          </EuiFlexGroup>
          <EuiFlexGroup gutterSize="s">
            <EuiButton size="s" onClick={getClickHandler(templates.monthly)} fullWidth>
              {ACTION.MONTHLY}
            </EuiButton>
            <EuiButton size="s" onClick={getClickHandler(templates.yearly)} fullWidth>
              {ACTION.YEARLY}
            </EuiButton>
          </EuiFlexGroup>
        </EuiFlexGroup>
      </EuiPopover>
    </>
  )
}

export { CronTemplates }
