import { EuiContextMenuItem, EuiIcon } from '@elastic/eui'
import { ACTION } from '@shared/config'

import { useRemoveAttributeGroups } from '../model'

const MenuItemRemoveAttributeGroup: ReactFc<ItemWithId> = ({ id }) => {
  const { removeFromGroups } = useRemoveAttributeGroups([id])

  return (
    <EuiContextMenuItem
      icon={<EuiIcon type="trash" color="danger" />}
      size="s"
      onClick={removeFromGroups}
    >
      {ACTION.DELETE}
    </EuiContextMenuItem>
  )
}

export { MenuItemRemoveAttributeGroup }
