import { PAGE_TITLES } from '@shared/config'
import { useMeta } from '@shared/hooks'
import { Layout } from '@shared/ui/layouts'
import { AutomaticTaskList } from '@widgets/settings/automatic-tasks'
import { AutomaticTasksHeader } from '@widgets/settings/automatic-tasks/automatic-tasks-header'

const AutomaticTasksPage: ReactFc = () => {
  useMeta({ title: PAGE_TITLES.SETTINGS.ATTRIBUTES.title })

  return (
    <Layout header={<AutomaticTasksHeader />}>
      <AutomaticTaskList />
    </Layout>
  )
}

export { AutomaticTasksPage }
