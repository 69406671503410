import { EuiFlexGroup, EuiFlexItem, EuiLink, EuiPanel, EuiSpacer } from '@elastic/eui'

import { type ImportFilesCardProps } from '../types'
import { FilesCardDownloadInfo } from './files-card-download-info'
import { FilesCardSubInfo } from './files-card-sub-info'

const ImportFilesCard: ReactFc<ImportFilesCardProps> = (imporFile) => {
  return (
    <EuiPanel>
      <EuiFlexGroup justifyContent="spaceBetween" alignItems="center">
        <EuiFlexItem grow={0}>
          <EuiLink href={imporFile.file_upload}>{imporFile.file_name}</EuiLink>
        </EuiFlexItem>

        <EuiFlexItem grow={0}>
          <FilesCardDownloadInfo
            importStatus={imporFile.import_status}
            progress={imporFile.progress || null}
            log={imporFile.log}
          />
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiSpacer size="m" />

      <FilesCardSubInfo
        createdAt={imporFile.created_at || ''}
        createdBy={imporFile.created_by}
        importTypes={imporFile.importTypes}
        importType={imporFile.import_type}
        importStatus={imporFile.import_status}
        fileId={imporFile.id}
        scheduledTo={imporFile.scheduled_to}
      />
    </EuiPanel>
  )
}

export { ImportFilesCard }
