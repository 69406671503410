import { type EuiThemeComputed } from '@elastic/eui'
import { css } from '@emotion/react'
import { type GetStyles } from '@shared/ui/styles'

export const getStyles: GetStyles = (euiTheme: EuiThemeComputed) => {
  return {
    crontub: css`
      margin-top: ${euiTheme.size.m};
      .euiFormRow {
        margin-block-start: 0;
      }
    `,
    badge: css`
      .euiBadge__text {
        white-space: normal !important;
      }
    `,
  }
}
