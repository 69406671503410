import { type ApiAttribute } from '@shared/api/types'
import { InfoTooltip } from '@shared/ui'

import { ATTRIBUTE_TYPE_HELP } from './attribute-type-help'
import { ATTRYBUTE_TYPE_MAP } from './attribute-type-map'

export const renderAttributeType: RenderColumn<ApiAttribute, 'attribute_type'> = (type) => {
  if (!type) {
    return null
  }

  return (
    <InfoTooltip tooltip={ATTRIBUTE_TYPE_HELP[type].DESCRIPTION}>
      {ATTRYBUTE_TYPE_MAP[type]}
    </InfoTooltip>
  )
}
