import { EuiFlexGroup, EuiPanel } from '@elastic/eui'
import { useAttributeListApi } from '@shared/api'
import { FormProvider } from 'react-hook-form'

import { useCatalogFilters } from '../model'
import { FilterAttribute } from './filter-attribute'
import { FilterParam } from './filter-param'

const CatalogFilters: ReactFc = () => {
  const { methods, activeFilters } = useCatalogFilters()
  const { data: attributeList } = useAttributeListApi()

  if (activeFilters.length === 0) {
    return null
  }

  return (
    <EuiPanel borderRadius="none" color="transparent" paddingSize="s" grow={false}>
      <FormProvider {...methods}>
        <EuiFlexGroup justifyContent="flexStart" gutterSize="s" wrap>
          {activeFilters.map((filterId) => {
            const filterAttribute = attributeList?.results.find((attribute) => {
              return attribute.slug === filterId
            })

            // Если id число, то это атрибут
            if (filterAttribute) {
              return <FilterAttribute key={`attribute-${filterId}`} attribute={filterAttribute} />
            }

            return <FilterParam key={`attribute-${filterId}`} skuParam={filterId} />
          })}
        </EuiFlexGroup>
      </FormProvider>
    </EuiPanel>
  )
}

export { CatalogFilters }
