import { App } from '@app/app'
import { EuiProvider } from '@elastic/eui'
import * as Sentry from '@sentry/react'
import ReactDom from 'react-dom/client'

/**
 * React.StrictMode отключен:
 * Отключенпо причине не полной поддержки ElasticUI
 * https://github.com/elastic/eui/issues/7774
 */

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://610c6d415e2b4cbd8ebecdeb495623df@sentry.inspector-cloud.ru/19',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: true,
        blockAllMedia: true,
      }),
    ],
    release: 'sku-hub-frontend@' + APP_VERSION,
    dist: 'sku-hub-frontend-' + APP_VERSION,
    tracesSampleRate: 0.2, // Отслеживаем 20% транзакций
    replaysSessionSampleRate: 0.1, // Записываем 10% всех сессий
    replaysOnErrorSampleRate: 1.0, // Записываем 100% сессий с ошибками
    environment: process.env.NODE_ENV || 'development',
  })
}

ReactDom.createRoot(document.getElementById('root')!).render(
  <EuiProvider>
    <App />
  </EuiProvider>,
)
