import { EuiFlexGroup, EuiFlexItem, EuiHorizontalRule, EuiPageSection } from '@elastic/eui'
import { ATTRIBUTE_GROUP_COLUMNS } from '@entities/attributes'
import { AddAttributeToCategory, RemoveAttributeFromCategory } from '@features/attributes'
import { API_ATTRIBUTE_MODEL, useAttributeListApi, type UseAttributeListApiArgs } from '@shared/api'
import { type ApiAttribute } from '@shared/api/types'
import { ACTION, PARAMS } from '@shared/config'
import { Search } from '@shared/ui'
import { Table, useSelectionTable, useSortTable } from '@shared/ui/table'
import { useParams, useSearchParams } from 'react-router'

const CategoryAttributesTab: ReactFc = () => {
  const { id: categoryIdParam = '' } = useParams()
  const categoryId = parseInt(categoryIdParam)
  const { sorting, sortList, onTableChange } = useSortTable<ApiAttribute>()
  const { selection } = useSelectionTable<ApiAttribute>()

  // Поиск по атрибутам
  const [searchParams] = useSearchParams()
  const search = searchParams.get('search')

  // Список атрибутов в категории
  const { isLoading, data: attributes } = useAttributeListApi({
    [PARAMS.MODEL]: API_ATTRIBUTE_MODEL.SKU,
    [PARAMS.CATEGORY]: [categoryId],
    search,
  } as UseAttributeListApiArgs)

  const attributeList = attributes?.results || []

  return (
    <EuiPageSection paddingSize="none">
      <EuiFlexGroup alignItems="stretch">
        <EuiFlexItem grow={false}>
          <Search searchParam="search" placeholder={ACTION.FIND_ATTRIBUTE} />
        </EuiFlexItem>

        <EuiFlexItem>
          <EuiFlexGroup justifyContent="flexEnd" gutterSize="none">
            <RemoveAttributeFromCategory />
            <AddAttributeToCategory />
          </EuiFlexGroup>
        </EuiFlexItem>
      </EuiFlexGroup>

      <EuiHorizontalRule margin="s" />

      <Table
        columns={ATTRIBUTE_GROUP_COLUMNS}
        items={sortList(attributeList)}
        sorting={sorting}
        onChange={onTableChange}
        selection={selection}
        loading={isLoading}
      />
    </EuiPageSection>
  )
}

export { CategoryAttributesTab }
