import { useNotificationsStore } from '@shared/ui/notifications'
import { useStyles } from '@shared/ui/styles'
import { NavLink, useNavigate } from 'react-router'

import { getStyles } from './styles'
import { type RenderLinkProps } from './types'

export const RenderLink = ({ href = '/', className, children }: RenderLinkProps) => {
  const styles = useStyles(getStyles)

  const navigate = useNavigate()

  const canLeave = useNotificationsStore.useCanLeave()
  const setOnLeave = useNotificationsStore.useSetOnLeave()

  const handleLinkClick = (event: React.MouseEvent) => {
    if (!canLeave) {
      event.preventDefault()

      setOnLeave(() => {
        navigate(href)
      })
    }
  }

  return (
    <NavLink to={href} className={className} css={styles.link} onClick={handleLinkClick}>
      {children}
    </NavLink>
  )
}
