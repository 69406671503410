import { EuiSelect } from '@elastic/eui'
import { isEqual } from 'lodash'
import { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'

import { PARAM_PUBLIC_OPTIONS, useCatalogFiltersList } from '../../model'
import { type FilterFields } from '../../types'

const FilterPublic: ReactFc = ({ skuParam, isValueEmpty }) => {
  const options = useCatalogFiltersList()
  const { getValues, setValue } = useFormContext<FilterFields>()

  const param = options?.find((option) => {
    return option.data.id === skuParam
  })

  // Когда фильтр появляется на экране задаём дефолтные значения: атрибут заполнен у sku
  useEffect(() => {
    if (isValueEmpty) {
      setValue(`filters.${skuParam}.filterParams`, PARAM_PUBLIC_OPTIONS[0].data)
    }
  }, [])

  const selectValue = PARAM_PUBLIC_OPTIONS.find((option) => {
    return isEqual(option.data, getValues(`filters.${skuParam}.filterParams`))
  })?.value

  const handleSelectChange: React.ChangeEventHandler<HTMLSelectElement> = (event) => {
    const option =
      PARAM_PUBLIC_OPTIONS.find((option) => {
        return option.value === event.target.value
      })?.data || []

    setValue(`filters.${skuParam}.filterParams`, option)
  }

  return (
    <EuiSelect
      prepend={param?.label}
      options={PARAM_PUBLIC_OPTIONS}
      value={selectValue}
      onChange={handleSelectChange}
      compressed
    />
  )
}

export { FilterPublic }
