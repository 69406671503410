import { getCookie } from '@shared/lib'
import createFetchClient, { type Middleware } from 'openapi-fetch'
import createClient from 'openapi-react-query'

import { API_BASE, DEFAULT_HEADERS } from './constans'
import { type paths } from './files-openapi-types'
import { errorHandler } from './lib'
import { type UpdatedPaths } from './types'

const throwOnError: Middleware = {
  async onResponse({ response }) {
    const clonedResponse = response.clone()
    errorHandler(clonedResponse)
  },
}

const fetchClient = createFetchClient<UpdatedPaths<typeof API_BASE>>({
  headers: DEFAULT_HEADERS,
  baseUrl: API_BASE,
})

const filesClient = createFetchClient<paths>({
  headers: {
    'X-CSRFToken': getCookie('csrftoken') || '',
  },
})

fetchClient.use(throwOnError)

const $api = createClient(fetchClient)
const $files = createClient(filesClient)

export { $api, $files }
