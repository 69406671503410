import { EuiPageSection } from '@elastic/eui'
import { useCategoriesColumns, useCategoriesList } from '@entities/categories'
import { type Category } from '@entities/categories/types'
import { useRouterParams } from '@shared/hooks'
import { Pagination, usePagination } from '@shared/ui'
import { Table, useSortTable } from '@shared/ui/table'
import { useSearchParams } from 'react-router'

import { ContextMenu } from './context-menu'

const CategorizationTable: ReactFc = () => {
  const { params } = useRouterParams()
  const columns = useCategoriesColumns()
  const { sorting, sortList, onTableChange } = useSortTable<Category>('id')

  // Поиск по атрибутам
  const [searchParams] = useSearchParams()
  const search = searchParams.get('search') || undefined

  const { isLoading, data = [], count } = useCategoriesList({ ...params, search: search })

  const paginationProps = usePagination(count)

  const columnsWithAction = [
    {
      width: '40px',
      actions: [
        {
          render: ContextMenu,
        },
      ],
    },
    ...columns,
  ]

  return (
    <EuiPageSection paddingSize="none">
      <Table
        columns={columnsWithAction}
        items={sortList(data)}
        sorting={sorting}
        onChange={onTableChange}
        loading={isLoading}
      />

      <Pagination {...paginationProps} />
    </EuiPageSection>
  )
}

export { CategorizationTable }
