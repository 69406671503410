import { EuiFilterButton } from '@elastic/eui'
import { useUserSettingsStore } from '@entities/user'
import { CATALOG_VIEW } from '@shared/config'

import { isCatalogView } from '../lib'
import { type CatalogToggleViewButtonProps } from './types'

export const CatalogToggleViewButton: ReactFc<CatalogToggleViewButtonProps> = ({
  viewType,
  iconType,
}) => {
  const toggleCatalogView = useUserSettingsStore.useToggleCatalogView()

  return (
    <EuiFilterButton
      iconType={iconType}
      iconSide="left"
      withNext={viewType === CATALOG_VIEW.CARDS}
      isSelected={isCatalogView(viewType)}
      hasActiveFilters={isCatalogView(viewType)}
      onClick={() => {
        return toggleCatalogView(viewType)
      }}
    >
      {viewType}
    </EuiFilterButton>
  )
}
