import { useAutomaticTaskApi } from '@entities/automatic_tasks'
import { useUpdateAutomaticTask } from '@features/automatic-tasks'
import { PAGE_TITLES } from '@shared/config'
import { useMeta } from '@shared/hooks'
import { Layout } from '@shared/ui/layouts'
import { AutomaticTaskHeader, AutomaticTaskInfo } from '@widgets/settings'
import { useParams } from 'react-router'

export const AutomaticTaskPage = () => {
  const { id } = useParams()
  const taskId = parseInt(id!)
  const { data: task } = useAutomaticTaskApi(taskId)

  const {
    isSaveButtonActive,
    updateAutomatickTask,
    isLoading: isAutomatickTaskSubmitting,
    methods,
  } = useUpdateAutomaticTask({ taskId })

  useMeta({ title: `${PAGE_TITLES.SETTINGS.AUTOMATIC_TASK.title} ${task?.name}` })

  return (
    <Layout
      header={
        <AutomaticTaskHeader
          updateAutomaticTask={updateAutomatickTask}
          isAutomaticTaskSubmitting={isAutomatickTaskSubmitting}
          isSaveButtonActive={isSaveButtonActive}
        />
      }
    >
      <AutomaticTaskInfo methods={methods} isTaskSubmitting={isAutomatickTaskSubmitting} />
    </Layout>
  )
}
