import { useUserSettingsStore } from '@entities/user'
import { Sidebar } from '@shared/ui/sidebar'

import { CatalogSidebarForm } from './catalog-sidebar-form'

export const CatalogSidebar: ReactFc = () => {
  const isCatalogSidebarOpen = useUserSettingsStore.useIsCatalogSidebarOpen()

  return (
    <Sidebar isOpen={isCatalogSidebarOpen}>
      <CatalogSidebarForm />
    </Sidebar>
  )
}
