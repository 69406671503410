import { isNotNull } from '@shared/lib'
import { useSearchParams } from 'react-router'

const useDefaultValues = (activeFilters: string[]) => {
  const [searchParams] = useSearchParams()

  // Создаём defaultValues для формы, проходимся по активным фильтрам и находим в searchParams значения для них
  return {
    filters: activeFilters
      .map((filter) => {
        const filterParams = Array.from(searchParams.entries())
          // Если ключ и фильтр совпадают, то записываем значение фильтре из урла
          .map(([key, value]) => {
            if (key.includes(filter)) {
              return {
                param: key,
                value,
              }
            }

            return null
          })
          .filter(isNotNull)

        return {
          [filter]: {
            filterParams,
          },
        }
      })
      .reduce((acc, curr) => {
        // Объединяем все объекты в один
        return { ...acc, ...curr }
      }, {}),
  }
}

export { useDefaultValues }
