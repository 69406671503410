import { DEFAULT_ITEMS_PER_PAGE, ITEMS_PER_PAGE_OPTIONS, PARAMS } from '@shared/config'
import { useRouterParams } from '@shared/hooks'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router'

import { usePaginationStore } from '../store'

const usePagination = (totalCount: number = 0) => {
  const [activePage, setActivePage] = useState(0)

  const { setParam, hasParam, getParamValues } = useRouterParams()
  const { pathname } = useLocation()

  const pageSizeList = usePaginationStore.usePageSizeList()
  const setPageSize = usePaginationStore.useSetPageSize()
  const queryPageSize = parseInt(getParamValues(PARAMS.SIZE)[0])

  const itemsPerPage = queryPageSize || pageSizeList[pathname] || DEFAULT_ITEMS_PER_PAGE
  const pageCount = Math.ceil(totalCount / itemsPerPage)
  const shouldShow = totalCount > Math.min(itemsPerPage, DEFAULT_ITEMS_PER_PAGE)

  useEffect(() => {
    if (!hasParam('size')) {
      setParam('size', itemsPerPage)
    }
  }, [])

  const onChangePage = (pageNumber: number) => {
    setParam('page', pageNumber + 1)

    return setActivePage(pageNumber)
  }

  const changeItemsPerPage = (pageSize: number) => {
    setPageSize({ [pathname]: pageSize })
    setActivePage(0)
    setParam('size', pageSize)
    setParam('page', 1)
  }

  return {
    componentProps: {
      pageCount,
      activePage,
      onChangePage,
      itemsPerPage,
      onChangeItemsPerPage: changeItemsPerPage,
      itemsPerPageOptions: ITEMS_PER_PAGE_OPTIONS,
    },
    shouldShow,
  }
}

export { usePagination }
