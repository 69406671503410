import { useAddUserApi } from '@entities/users'
import { API_QUERY_KEY, queryClient } from '@shared/api'
import { NOTIFICATIONS } from '@shared/config'
import { useNotificationsStore } from '@shared/ui/notifications'
import { useForm } from 'react-hook-form'

import { type AddUserFormInputs, type UseAddUserArgs } from '../types'

const useAddUser = ({ onClose, onSuccessCallback }: UseAddUserArgs) => {
  const addNotification = useNotificationsStore.useAddNotification()
  const { mutate: addUser, isPending: isUserSubmitting } = useAddUserApi()

  const methods = useForm<AddUserFormInputs>()

  const onSuccess = () => {
    queryClient.refetchQueries({ queryKey: API_QUERY_KEY.USERS })
    methods.reset()
    onClose()
    addNotification(NOTIFICATIONS.USER_CREATE_SUCCESS)

    if (onSuccessCallback) {
      onSuccessCallback()
    }
  }

  const handleAddUser = methods.handleSubmit((userData) => {
    const body = {
      username: userData.username,
      password: userData.password,
    }

    return addUser({ body }, { onSuccess })
  })

  const handleModalClose = () => {
    methods.reset()
    onClose()
  }

  return {
    methods,
    handleAddUser,
    handleModalClose,
    isSubmitting: isUserSubmitting,
  }
}

export { useAddUser }
