import { EuiButtonEmpty, EuiPanel, EuiText } from '@elastic/eui'
import { css } from '@emotion/react'
import { ACTION, ELEMENTS_L_WIDTH, MESSAGE } from '@shared/config'
import { useDialog } from '@shared/hooks'
import { Modal } from '@shared/ui/modal'
import { useEffect, useState } from 'react'

const logButton = css`
  padding: 0;
  background-color: transparent !important;
  block-size: unset;
  .euiButtonEmpty__content {
    gap: 0;
  }
`

const LogButton: ReactFc<{ url?: string }> = ({ url }) => {
  const [logText, setLogText] = useState('')

  const { isOpen, handleOpenDialog, handleCloseDialog } = useDialog()

  const getLogText = async (url?: string) => {
    try {
      const response = await fetch(`${window.location.origin}${url}`)
      const text = await response.text()

      setLogText(text)
    } catch (error) {
      // TODO: Добавить всплывашку
      console.error(error)
    }
  }

  const handleDownloadLog = () => {
    if (!url) {
      return
    }

    location.href = url
  }

  useEffect(() => {
    if (url) {
      getLogText(url)
    }
  }, [])

  if (!url) {
    return null
  }

  return (
    <>
      <EuiButtonEmpty
        iconType="document"
        iconSize="s"
        size="xs"
        color="text"
        onClick={handleOpenDialog}
        css={logButton}
      />
      <Modal
        title={MESSAGE.IMPORT_LOG}
        onClose={handleCloseDialog}
        isOpen={isOpen}
        maxWidth={ELEMENTS_L_WIDTH}
        submitButtonText={ACTION.DOWNLOAD}
        onSubmit={handleDownloadLog}
      >
        <EuiPanel className="eui-scrollBar">
          <EuiText size="s" style={{ maxWidth: '100%', overflow: 'auto' }}>
            {`${logText}`}
            {url}
          </EuiText>
        </EuiPanel>
      </Modal>
    </>
  )
}

export { LogButton }
