import { css } from '@emotion/react'

export const treeWrapper = css`
  .euiTreeView__nodeLabel,
  .euiCheckbox__label {
    width: 100%;
    vertical-align: middle;
  }
  .euiTreeView__nodeInner:focus {
    background-color: transparent;
  }
  .euiTreeView__nodeInner:hover {
    // TODO: заменить на переменную из темы
    background-color: rgba(223, 229, 239, 0.2);
  }
`
