import { EuiFlexGroup, EuiFlexItem, EuiIcon, EuiText, EuiToolTip } from '@elastic/eui'
import { AUTOMATIC_TASK_NAME, MESSAGE } from '@shared/config'
import { getLocalDate } from '@shared/lib'

import { type TaskCardSubInfoProps } from './types'

const TaskCardSubInfo: ReactFc<TaskCardSubInfoProps> = ({
  createdAt,
  createdBy,
  taskType,
  lastLaunchAt,
}) => {
  const subInfoItems = [
    { iconType: 'user', text: createdBy, toolTip: MESSAGE.INITIATOR },
    { iconType: 'calendar', text: getLocalDate(createdAt), toolTip: MESSAGE.CREATE_DATE },
    {
      iconType: 'document',
      text: AUTOMATIC_TASK_NAME[taskType],
      toolTip: MESSAGE.TYPE,
    },
    {
      iconType: lastLaunchAt ? 'timeslider' : null,
      text: lastLaunchAt,
      toolTip: MESSAGE.LAST_LAUNCH,
    },
  ]

  return (
    <EuiFlexGroup alignItems="center">
      {subInfoItems.map((item, index) => {
        const { iconType, text, toolTip } = item

        return (
          <EuiFlexItem key={index} grow={false}>
            <EuiToolTip position="top" content={toolTip}>
              <EuiFlexGroup alignItems="center" gutterSize="s">
                {iconType && <EuiIcon type={iconType} size="m" />}

                <EuiText size="s">{text}</EuiText>
              </EuiFlexGroup>
            </EuiToolTip>
          </EuiFlexItem>
        )
      })}
    </EuiFlexGroup>
  )
}

export { TaskCardSubInfo }
