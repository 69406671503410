import { EuiPageSection } from '@elastic/eui'
import { ATTRIBUTE_GROUPS_COLUMNS, useAttributeGroupsList } from '@entities/attributes'
import { type AttributeGroup } from '@entities/attributes/types'
import { Pagination, usePagination } from '@shared/ui'
import { Table, useSelectionTable, useSortTable } from '@shared/ui/table'
import { useSearchParams } from 'react-router'

import { GroupsContextMenu } from './groups-context-menu'

export const AttributeGroupsTable: ReactFc = () => {
  const { sorting, sortList, onTableChange } = useSortTable<AttributeGroup>('order')
  const { selection } = useSelectionTable<AttributeGroup>()

  // Поиск по группам атрибутов
  const [searchParams] = useSearchParams()
  const search = searchParams.get('search') || undefined

  // Пагинация
  const size = Number(searchParams.get('size')) || undefined
  const page = Number(searchParams.get('page')) || undefined

  const { isLoading, data, count } = useAttributeGroupsList({
    search,
    size,
    page,
  })

  const paginationProps = usePagination(count)

  const columnsWithAction = [
    {
      width: '40px',
      actions: [
        {
          render: GroupsContextMenu,
        },
      ],
    },
    ...ATTRIBUTE_GROUPS_COLUMNS,
  ]

  return (
    <EuiPageSection paddingSize="none">
      <Table
        columns={columnsWithAction}
        items={sortList(data)}
        sorting={sorting}
        onChange={onTableChange}
        selection={selection}
        loading={isLoading}
      />

      <Pagination {...paginationProps} />
    </EuiPageSection>
  )
}
