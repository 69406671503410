import { EuiFlexItem, EuiIconTip } from '@elastic/eui'
import { ERROR, LABEL } from '@shared/config'
import { PasswordInput } from '@shared/ui/form'

import { type PasswordComponentProps } from './types'

const PasswordComponent: ReactFc<PasswordComponentProps> = ({ getPasswordValue, isSubmitting }) => {
  const passwordValidationOptions = {
    required: ERROR.ENTER_PASSWORD,
    minLength: {
      value: 8,
      message: ERROR.USER_PASSWORD_MIN_LENGTH,
    },
    maxLength: {
      value: 150,
      message: ERROR.USER_NAME_MAX_LENGTH,
    },
    validate: {
      numericPassword: (value: string) => {
        return /^(?!\d+$).*/.test(value) || ERROR.USER_PASSWORD_NUMERIC
      },
    },
  }

  const confirmPasswordValidateOptions = {
    required: ERROR.ENTER_PASSWORD,
    validate: {
      passwordMatch: (value: string) => {
        return value === getPasswordValue() || ERROR.USER_PASSWORD_MATCH
      },
    },
  }

  const passwordTooltip = (
    <>
      • Пароль не должен быть слишком похож на другую вашу личную информацию
      <br />
      • Ваш пароль должен содержать как минимум 8 символов
      <br />
      • Пароль не должен быть слишком простым и распространенным
      <br />• Пароль не может состоять только из цифр
    </>
  )

  return (
    <>
      <EuiFlexItem>
        <PasswordInput
          name="password"
          placeholder={LABEL.PASSWORD}
          registerOptions={passwordValidationOptions}
          inputProps={{
            disabled: isSubmitting,
            autoComplete: 'new-password',
            append: [<EuiIconTip key="username" content={passwordTooltip} />],
            compressed: true,
          }}
          isRequired
        />
      </EuiFlexItem>
      <EuiFlexItem>
        <PasswordInput
          name="confirmPassword"
          placeholder={LABEL.CONFIRM_PASSWORD}
          registerOptions={confirmPasswordValidateOptions}
          inputProps={{
            disabled: isSubmitting,
            autoComplete: 'new-password',
            compressed: true,
          }}
          isRequired
        />
      </EuiFlexItem>
    </>
  )
}

export { PasswordComponent }
