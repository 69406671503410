import { MenuItemRemoveAttributeOption, MenuItemUpdateAttributeOption } from '@features/attributes'
import { UpdateAttributeOptionModal } from '@features/attributes/add-update-attribute-option/ui/update-attribute-option-modal'
import { type ApiAttributeOption } from '@shared/api/types'
import { useDialog } from '@shared/hooks'
import { TableContextMenu } from '@shared/ui/context-menu'

const ContextMenu = (props: ApiAttributeOption) => {
  const { isOpen, handleOpenDialog: showModal, handleCloseDialog: closeModal } = useDialog()

  return (
    <>
      <TableContextMenu>
        <MenuItemUpdateAttributeOption showModal={showModal} />
        <MenuItemRemoveAttributeOption id={props.id} />
      </TableContextMenu>

      <UpdateAttributeOptionModal
        attributeId={props.attribute}
        optionId={props.id}
        isOpen={isOpen}
        closeModal={closeModal}
      />
    </>
  )
}

export { ContextMenu }
