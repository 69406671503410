import { useUpdateAttributeValue } from '@entities/attributes'
import { useUpdateCategoryApi } from '@entities/categories'
import { useCategoryApi } from '@entities/categories'
import { API_QUERY_KEY, ATTRIBUTE_FLAGS, extractAttribute, queryClient } from '@shared/api'
import { ERROR, NOTIFICATIONS } from '@shared/config'
import { isNotNull } from '@shared/lib'
import { useNotificationsStore } from '@shared/ui/notifications'
import { useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'

import { type CategoryFormInputs } from './types'
import { useCategoryForm } from './use-category-form'

export const useUpdateCategory = (id: number, closeModal: EmptyCallback) => {
  const methods = useForm<CategoryFormInputs>()
  const addNotification = useNotificationsStore.useAddNotification()

  const category = useCategoryApi(id)
  const nameAttribute = extractAttribute(category.data, ATTRIBUTE_FLAGS.NAME)
  const name = `${nameAttribute?.value}`
  const nameValueId = nameAttribute?.value_id

  const { isCatalogOptionsLoading, catalogOptions } = useCategoryForm()

  const selectedCatalogOptions = useMemo(() => {
    return category.data?.object_group?.map((catalog) => {
      return catalogOptions?.find((catalogOption) => {
        return catalogOption.value === catalog
      })
    })
  }, [category])

  useEffect(() => {
    if (category.data && catalogOptions) {
      methods.reset({
        name,
        object_group: selectedCatalogOptions || [],
        parent: category.data.parent || undefined,
      })
    }
  }, [category.data, isCatalogOptionsLoading])

  const update = useUpdateCategoryApi()

  // TODO: Убрать после обновления category_value в API
  const rename = useUpdateAttributeValue()

  // Обновляем категорию
  const updateCategory = methods.handleSubmit(({ name, object_group, parent }) => {
    const catalogs = object_group
      .map(({ value }) => {
        return value || null
      })
      .filter(isNotNull)

    if (category.data?.parent !== parent || category.data?.object_group !== catalogs) {
      update.mutate({
        params: {
          path: {
            id,
          },
        },
        body: {
          object_group: catalogs,
          parent,
        },
      })
    }

    rename.mutate(
      {
        params: {
          path: {
            id: nameValueId!,
          },
        },
        body: {
          value: name,
        },
      },
      {
        onError: () => {
          methods.setError('root', {
            type: 'manual',
            message: ERROR.SERVER,
          })
        },
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: API_QUERY_KEY.CATEGORY_LIST })

          queryClient.invalidateQueries({
            queryKey: API_QUERY_KEY.CATEGORY(id),
          })

          closeModal()

          addNotification(NOTIFICATIONS.CATEGORY_UPDATE_SUCCESS)
        },
      },
    )
  })

  const isSubmitting = rename.isPending || update.isPending

  return {
    closeModal,
    methods,
    updateCategory,
    isSubmitting,
  }
}
