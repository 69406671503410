import { useAttributeApi } from '@entities/attributes'
import { API_ATTRIBUTE_TYPE } from '@shared/api'
import { LABEL } from '@shared/config'
import { useParams } from 'react-router'

import { AttributeInfo } from '../ui/attribute-info'
import { OptionsList } from '../ui/options-list'

const useAttributeTabsList = () => {
  const { id: attributeIdParam } = useParams()
  const attributeId = Number(attributeIdParam)
  const { data: attribute } = useAttributeApi(attributeId)

  const attributeTabsList = [
    {
      id: 'settings',
      name: LABEL.ATTRIBUTE,
      content: <AttributeInfo />,
    },
  ]

  if (attribute?.attribute_type === API_ATTRIBUTE_TYPE.LIST) {
    attributeTabsList.push({
      id: 'options-list',
      name: LABEL.OPTIONS_LIST,
      content: <OptionsList />,
    })
  }

  return attributeTabsList
}

export { useAttributeTabsList }
