import { EuiContextMenuItem, EuiIcon } from '@elastic/eui'
import { ACTION } from '@shared/config'

import { type MenuItemUpdateAttributeOptionProps } from './types'

const MenuItemUpdateAttributeOption: ReactFc<MenuItemUpdateAttributeOptionProps> = ({
  showModal,
  closeContextMenu,
}) => {
  const onClick = () => {
    showModal()

    if (closeContextMenu) {
      closeContextMenu()
    }
  }

  return (
    <EuiContextMenuItem icon={<EuiIcon type="pencil" color="primary" />} size="s" onClick={onClick}>
      {ACTION.EDIT}
    </EuiContextMenuItem>
  )
}

export { MenuItemUpdateAttributeOption }
