import {
  $api,
  API_QUERY_KEY,
  ATTRIBUTE_FLAGS,
  extractAttribute,
  queryClient,
  useObjectGroupApi,
} from '@shared/api'
import { ERROR, NOTIFICATIONS } from '@shared/config'
import { useNotificationsStore } from '@shared/ui/notifications'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'

import { type UpdateAttributeGroupFormInputs } from './types'

export const useUpdateAttributeGroup = (id: number, closeModal: EmptyCallback) => {
  const methods = useForm<UpdateAttributeGroupFormInputs>()
  const addNotification = useNotificationsStore.useAddNotification()

  const attributeGroup = useObjectGroupApi(id)
  const nameAttribute = extractAttribute(attributeGroup.data, ATTRIBUTE_FLAGS.NAME)
  const name = `${nameAttribute?.value}`
  const nameValueId = nameAttribute?.value_id

  useEffect(() => {
    methods.reset({
      name,
      order: attributeGroup.data?.order || undefined,
    })
  }, [attributeGroup.data])

  const update = $api.useMutation('patch', '/object_group_value/{id}/', {
    onError: () => {
      methods.setError('root', {
        type: 'manual',
        message: ERROR.SERVER,
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: API_QUERY_KEY.ATTRIBUTE_GROUP_LIST })
      queryClient.invalidateQueries({ queryKey: API_QUERY_KEY.ATTRIBUTE_GROUP(id) })
      closeModal()
      methods.reset()
      addNotification(NOTIFICATIONS.ATTRIBUTE_GROUP_UPDATE_SUCCESS)
    },
  })

  const updateGroup = methods.handleSubmit(({ name, order }) => {
    update.mutate({
      params: {
        path: {
          id,
        },
      },
      body: {
        order,
        value_id: nameValueId?.toString(),
        value: name,
      },
    })
  })

  const isSubmitting = update.isPending

  return {
    methods,
    updateGroup,
    isSubmitting,
  }
}
