import { EuiPageSidebar, EuiResizableButton } from '@elastic/eui'
import { useResizableButton } from '@shared/hooks'
import { useStyles } from '@shared/ui/styles'

import { getStyles } from './styles'
import { type SidebarProps } from './types'

export const Sidebar: ReactFc<SidebarProps> = ({ children, isOpen }) => {
  const styles = useStyles(getStyles)
  const { width, resizeButtonRef, onMouseDown, onKeyDown } = useResizableButton()

  const minWidth = isOpen ? width : 0

  return (
    <EuiPageSidebar minWidth={minWidth} sticky css={styles.sidebar}>
      {children}

      <EuiResizableButton
        ref={resizeButtonRef}
        isHorizontal
        onMouseDown={onMouseDown}
        onTouchStart={onMouseDown}
        onKeyDown={onKeyDown}
        css={styles.resizeButton}
      />
    </EuiPageSidebar>
  )
}
