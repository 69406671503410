import { type EuiThemeComputed } from '@elastic/eui'
import { css } from '@emotion/react'
import { type GetStyles } from '@shared/ui/styles'

export const getStyles: GetStyles = (euiTheme: EuiThemeComputed) => {
  return {
    dates: css`
      block-size: ${euiTheme.size.xl} !important;
    `,
  }
}
