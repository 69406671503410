import { type GetSelectInputOptionsArgs } from './types'

const getSelectInputOptions = <T>({
  items,
  valueKey,
  inputDisplayKey,
}: GetSelectInputOptionsArgs<T>) => {
  if (!items) {
    return []
  }

  const itemsArray = Array.isArray(items) ? items : items.results

  return itemsArray.map((item: T) => {
    const value = item[valueKey]
    const inputDisplay = item[inputDisplayKey]

    const finalValue = typeof value === 'string' || typeof value === 'number' ? value : ''
    const finalInputDisplay = typeof inputDisplay === 'string' ? inputDisplay : ''

    return { inputDisplay: finalInputDisplay, value: finalValue }
  })
}

export { getSelectInputOptions }
