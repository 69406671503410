import { EuiFlexGroup, EuiFormRow, EuiSpacer } from '@elastic/eui'
import { getKeys } from '@shared/lib'
import { useStyles } from '@shared/ui/styles'
import { type FieldError, useFormContext } from 'react-hook-form'

import { type InputProps } from '../types'
import { CronTemplates } from './cron-templates'
import { getCronFields } from './get-cron-fields'
import { validate } from './helpers'
import { getStyles } from './styles'
import { type CronInputProps, type CrontabSchedule, type HandleValidationArgs } from './types'

const CronInput: ReactFc<InputProps<CronInputProps>> = ({ name, label, isDisabled }) => {
  const styles = useStyles(getStyles)

  const {
    formState: { errors },
    watch,
    setError,
    clearErrors,
    setValue,
    getValues,
  } = useFormContext()

  // Нужно для перерисовки компонента
  watch(name)

  const crontabErrors = errors[name] as Record<string, FieldError>

  const getErrorText = () => {
    const keys = Object.keys(crontabErrors)

    return crontabErrors[keys[0]].message
  }

  const errorText = crontabErrors && getErrorText()

  const handleValidation = ({ name: nameExternal, value }: HandleValidationArgs) => {
    const message = validate({ name: nameExternal, value })
    const fullName = `${name}.${nameExternal}`

    if (message) {
      setError(fullName, { message })

      return
    }

    if (crontabErrors?.[nameExternal]?.message) {
      clearErrors(fullName)
    }
  }

  const handleChange = ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>) => {
    const inputName = name.split('.')[1] as keyof CrontabSchedule

    setValue(name, value)
    handleValidation({ name: inputName, value })
  }

  const handleTemplatePick = (template: Required<CrontabSchedule>) => {
    setValue(name, template)

    getKeys(template).forEach((key) => {
      handleValidation({ name: key, value: template[key] })
    })
  }

  const cronFields = getCronFields({
    crontabName: name,
    getValues,
    errors: crontabErrors,
    onChange: handleChange,
    isDisabled,
  })

  const crontabTopFields = cronFields.slice(0, 3)
  const crontabBottomFields = cronFields.slice(3)

  return (
    <EuiFormRow label={label} error={errorText} isInvalid={!!errorText}>
      <div css={styles.crontub}>
        {!isDisabled && <CronTemplates onChange={handleTemplatePick} />}

        <EuiSpacer size="s" />

        <EuiFlexGroup direction="column" gutterSize="s">
          <EuiFlexGroup direction="column" gutterSize="xs">
            <EuiFlexGroup>{crontabTopFields}</EuiFlexGroup>
            <EuiFlexGroup>{crontabBottomFields}</EuiFlexGroup>
          </EuiFlexGroup>
        </EuiFlexGroup>
      </div>
    </EuiFormRow>
  )
}

export { CronInput }
