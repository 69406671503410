import { type ApiObjectAttribute } from '@shared/api/types'

const getPriorityOrderedAttributes = (attributes: ApiObjectAttribute[] | undefined) => {
  const nameAttr = attributes?.find((attr) => {
    return attr.is_name === true
  })

  const skuAttr = attributes?.find((attr) => {
    return attr.is_sku === true
  })

  if (!nameAttr && !skuAttr) {
    return attributes || []
  }

  const result =
    attributes
      ?.sort((first, second) => {
        return first.id - second.id
      })
      .filter((attr) => {
        return attr !== nameAttr && attr !== skuAttr
      }) || []

  if (skuAttr) {
    result.unshift(skuAttr)
  }

  if (nameAttr) {
    result.unshift(nameAttr)
  }

  return result
}

export { getPriorityOrderedAttributes }
