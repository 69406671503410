import { EuiButton } from '@elastic/eui'
import { ApiPaths } from '@shared/api/openapi-types'

import { type DownloadTemplateButtonProps } from './types'

const DownloadTemplateButton: ReactFc<DownloadTemplateButtonProps> = ({ name, type }) => {
  return (
    <EuiButton
      href={`${ApiPaths.import_retrieve}?import_type=${type}`}
      fullWidth
      iconType="document"
      color="text"
    >
      {name}
    </EuiButton>
  )
}

export { DownloadTemplateButton }
