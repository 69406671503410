import { EuiButtonEmpty } from '@elastic/eui'
import { AddTaskModal } from '@features/automatic-tasks'
import { ACTION } from '@shared/config'
import { useDialog } from '@shared/hooks'

const AddAutomaticTask: ReactFc = () => {
  const { isOpen, handleOpenDialog, handleCloseDialog } = useDialog()

  return (
    <>
      <EuiButtonEmpty
        iconType="plusInCircle"
        color="primary"
        iconSide="right"
        onClick={handleOpenDialog}
        size="s"
      >
        {ACTION.ADD_TASK}
      </EuiButtonEmpty>
      <AddTaskModal isOpen={isOpen} onClose={handleCloseDialog} />
    </>
  )
}

export { AddAutomaticTask }
