import { EuiPageSection } from '@elastic/eui'
import {
  getUserFieldList,
  USERS_COLUMNS,
  type UsersTableRow,
  useUsersListApi,
} from '@entities/users'
import { useRouterParams } from '@shared/hooks'
import { Pagination, usePagination } from '@shared/ui'
import { Table, useSortTable } from '@shared/ui/table'
import { useSearchParams } from 'react-router'

const UsersTable: ReactFc = () => {
  const { params } = useRouterParams()
  const { sorting, sortList, onTableChange } = useSortTable<UsersTableRow>()
  // TODO: понадобится при реализации действий с множественным выбором
  // const { selection } = useSelectionTable<ApiValidator>()

  // Поиск по пользователям
  const [searchParams] = useSearchParams()
  const search = searchParams.get('search') || undefined

  const { isLoading, data: users } = useUsersListApi({ ...params, search })

  const paginationProps = usePagination(users?.count)

  const usersList = users?.results
  const userFiledList = getUserFieldList(usersList)

  return (
    <EuiPageSection paddingSize="none">
      <Table
        columns={USERS_COLUMNS}
        items={sortList(userFiledList)}
        sorting={sorting}
        onChange={onTableChange}
        // TODO: понадобится при реализации действий с множественным выбором
        // selection={selection}
        loading={isLoading}
      />

      <Pagination {...paginationProps} />
    </EuiPageSection>
  )
}

export { UsersTable }
