import { ATTRIBUTE_FLAGS } from '@shared/api'
import { type ApiAttribute } from '@shared/api/types'
import { ATTRIBUTE_LABEL } from '@shared/config'

const getAttributeNameLabel = (attribute: ApiAttribute): string => {
  if (attribute[ATTRIBUTE_FLAGS.NAME]) {
    return ATTRIBUTE_LABEL.NAME
  }

  if (attribute[ATTRIBUTE_FLAGS.BRAND]) {
    return ATTRIBUTE_LABEL.BRAND
  }

  if (attribute[ATTRIBUTE_FLAGS.SKU]) {
    return ATTRIBUTE_LABEL.SKU
  }

  if (attribute[ATTRIBUTE_FLAGS.PREVIEW]) {
    return ATTRIBUTE_LABEL.PREVIEW
  }

  return ''
}

export { getAttributeNameLabel }
