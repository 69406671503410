// TODO: Для перевода расставить useEuiI18n токены

import { LABEL } from '@shared/config'

import { CRON_FIELD_NAME, type ValidateArgs } from './types'

const getCronValidators = () => {
  return {
    minute: {
      validate: (value: string) => {
        if (value === '*' || value === '') {
          return false
        }

        const pattern = /^(\*|\d{1,2})(\/\d{1,2})?$/

        if (!pattern.test(value)) {
          return 'Минута должна быть "*" или числом от 0 до 59, или "*/число", или "число/число"'
        }

        if (value.includes('/')) {
          const [start, step] = value.split('/')

          if (
            start !== '*' &&
            (isNaN(parseInt(start)) || parseInt(start) < 0 || parseInt(start) > 59)
          ) {
            return 'Некорректное начальное значение в шаге минут'
          }

          if (isNaN(parseInt(step)) || parseInt(step) <= 0 || parseInt(step) > 59) {
            return 'Некорректный шаг минут'
          }

          return false
        }

        if (isNaN(parseInt(value))) {
          return 'Минута должна быть числом'
        }

        if (parseInt(value) < 0 || parseInt(value) > 59) {
          return 'Минута должна быть в диапазоне от 0 до 59'
        }

        return false
      },
    },
    hour: {
      validate: (value: string) => {
        if (value === '*' || value === '') {
          return false
        }

        const pattern = /^(\*|\d{1,2})(\/\d{1,2})?$/

        if (!pattern.test(value)) {
          return 'Час должен быть "*" или числом от 0 до 23, или "*/число", или "число/число"'
        }

        if (value.includes('/')) {
          const [start, step] = value.split('/')

          if (
            start !== '*' &&
            (isNaN(parseInt(start)) || parseInt(start) < 0 || parseInt(start) > 23)
          ) {
            return 'Некорректное начальное значение в шаге часов'
          }

          if (isNaN(parseInt(step)) || parseInt(step) <= 0 || parseInt(step) > 23) {
            return 'Некорректный шаг часов'
          }

          return false
        }

        if (isNaN(parseInt(value))) {
          return 'Час должен быть числом'
        }

        if (parseInt(value) < 0 || parseInt(value) > 23) {
          return 'Час должен быть в диапазоне от 0 до 23'
        }

        return false
      },
    },
    day_of_week: {
      validate: (value: string) => {
        if (value === '*' || value === '') {
          return false
        }

        const pattern = /^(\*|\d)(\/\d)?$/

        if (!pattern.test(value)) {
          return 'День недели должен быть "*" или числом от 0 до 6, или "*/число", или "число/число"'
        }

        if (value.includes('/')) {
          const [start, step] = value.split('/')

          if (
            start !== '*' &&
            (isNaN(parseInt(start)) || parseInt(start) < 0 || parseInt(start) > 6)
          ) {
            return 'Некорректное начальное значение в шаге дней недели'
          }

          if (isNaN(parseInt(step)) || parseInt(step) <= 0 || parseInt(step) > 6) {
            return 'Некорректный шаг дней недели'
          }

          return false
        }

        if (isNaN(parseInt(value))) {
          return 'День недели должен быть числом'
        }

        if (parseInt(value) < 0 || parseInt(value) > 6) {
          return 'День недели должен быть в диапазоне от 0 до 6'
        }

        return false
      },
    },
    day_of_month: {
      validate: (value: string) => {
        if (value === '*' || value === '') {
          return false
        }

        const pattern = /^(\*|\d{1,2})(\/\d{1,2})?$/

        if (!pattern.test(value)) {
          return 'День месяца должен быть "*" или числом от 1 до 31, или "*/число", или "число/число"'
        }

        if (value.includes('/')) {
          const [start, step] = value.split('/')

          if (
            start !== '*' &&
            (isNaN(parseInt(start)) || parseInt(start) < 1 || parseInt(start) > 31)
          ) {
            return 'Некорректное начальное значение в шаге дней месяца'
          }

          if (isNaN(parseInt(step)) || parseInt(step) <= 0 || parseInt(step) > 31) {
            return 'Некорректный шаг дней месяца'
          }

          return false
        }

        if (isNaN(parseInt(value))) {
          return 'День месяца должен быть числом'
        }

        if (parseInt(value) < 1 || parseInt(value) > 31) {
          return 'День месяца должен быть в диапазоне от 1 до 31'
        }

        return false
      },
    },
    month_of_year: {
      validate: (value: string) => {
        if (value === '*' || value === '') {
          return false
        }

        const pattern = /^(\*|\d{1,2})(\/\d{1,2})?$/

        if (!pattern.test(value)) {
          return 'Месяц должен быть "*" или числом от 1 до 12, или "*/число", или "число/число"'
        }

        if (value.includes('/')) {
          const [start, step] = value.split('/')

          if (
            start !== '*' &&
            (isNaN(parseInt(start)) || parseInt(start) < 1 || parseInt(start) > 12)
          ) {
            return 'Некорректное начальное значение в шаге месяцев'
          }

          if (isNaN(parseInt(step)) || parseInt(step) <= 0 || parseInt(step) > 12) {
            return 'Некорректный шаг месяцев'
          }

          return false
        }

        if (isNaN(parseInt(value))) return 'Месяц должен быть числом'

        if (parseInt(value) < 1 || parseInt(value) > 12) {
          return 'Месяц должен быть в диапазоне от 1 до 12'
        }

        return false
      },
    },
  }
}

const validate = ({ name, value }: ValidateArgs) => {
  const cronValidators = getCronValidators()

  return cronValidators[name].validate(value)
}

const getCronTemplates = () => {
  return {
    hourly: {
      minute: '0',
      hour: '*',
      day_of_month: '*',
      month_of_year: '*',
      day_of_week: '*',
    },
    daily: {
      minute: '0',
      hour: '0',
      day_of_month: '*',
      month_of_year: '*',
      day_of_week: '*',
    },
    weekly: {
      minute: '0',
      hour: '0',
      day_of_month: '*',
      month_of_year: '*',
      day_of_week: '0',
    },
    monthly: {
      minute: '0',
      hour: '0',
      day_of_month: '1',
      month_of_year: '*',
      day_of_week: '*',
    },
    yearly: {
      minute: '0',
      hour: '0',
      day_of_month: '1',
      month_of_year: '1',
      day_of_week: '*',
    },
  }
}

const getLabel = (key: string) => {
  switch (key.toLowerCase()) {
    case CRON_FIELD_NAME.MINUTE:
      return LABEL.MINUTES
    case CRON_FIELD_NAME.HOUR:
      return LABEL.HOURS
    case CRON_FIELD_NAME.DAY_OF_WEEK:
      return LABEL.DAYS_OF_WEEK
    case CRON_FIELD_NAME.DAY_OF_MONTH:
      return LABEL.DAYS
    case CRON_FIELD_NAME.MONTH_OF_YEAR:
      return LABEL.MONTHS
    default:
      return key
  }
}

export { getCronValidators, getCronTemplates, validate, getLabel }
