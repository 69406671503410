import { LoginInput } from '@entities/user'
import { useUserInfoFields } from '@entities/users'
import { ChangeUserPassword, UserPermissionsEditor } from '@features/user'
import { LABEL } from '@shared/config'
import { InfoPageRow } from '@shared/ui'
import { TextInput } from '@shared/ui/form'

import { type UserInfoRowsProps } from './types'

const UserInfoRows: ReactFc<UserInfoRowsProps> = ({ id: userId, isSubmitting }) => {
  const { userFields, isLoading: isUserFieldsLoading } = useUserInfoFields(userId)

  const isDisabled = isUserFieldsLoading || isSubmitting

  const rowsConfig = userFields?.map(({ name, label }) => {
    return {
      name: label,
      field: <TextInput name={name} inputProps={{ disabled: isDisabled, compressed: true }} />,
    }
  })

  rowsConfig?.unshift({
    name: LABEL.LOGIN,
    field: <LoginInput isSubmitting={isDisabled} inputProps={{ compressed: true }} />,
  })

  rowsConfig?.push({
    name: LABEL.PASSWORD,
    field: <ChangeUserPassword />,
  })

  rowsConfig?.push({
    name: LABEL.PERMISSIONS,
    field: <UserPermissionsEditor userId={userId} />,
  })

  const rows = rowsConfig?.map(({ name, field }) => {
    return <InfoPageRow key={name} label={name || ''} field={field} />
  })

  return rows || []
}

export { UserInfoRows }
