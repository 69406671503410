import { $api } from '@shared/api'

import { type UseImportFilesListApiArgs } from '../types'

const useImportFilesListApi = ({ queryArgs, refetchInterval }: UseImportFilesListApiArgs) => {
  return $api.useQuery('get', '/master_list/', {
    params: {
      query: {
        ...queryArgs,
      },
    },
    refetchInterval,
  })
}

export { useImportFilesListApi }
