import { MESSAGE, NOTIFICATIONS, ROUTES } from '@shared/config'
import { notificationsStore } from '@shared/ui/notifications'
import _ from 'lodash'

const errorHandler = async (response: Response) => {
  // Ошибка авторизации
  if (response.status === 401) {
    // Перенаправляем на страницу логина
    window.location.href = ROUTES.LOGIN.$path()
  }

  // Ошибка доступа
  if (response.status === 403) {
    notificationsStore.getState().addNotification(NOTIFICATIONS.PERMISSIONS_ERROR)

    return undefined
  }

  // Не найдено
  if (response.status === 404) {
    notificationsStore.getState().addNotification(NOTIFICATIONS.NOT_FOUND)

    return undefined
  }

  // Ошибка сервера
  if (response.status >= 500) {
    notificationsStore.getState().addNotification(NOTIFICATIONS.SERVER_ERROR)

    return undefined
  }

  // Прочие ошибки
  if (response.status >= 400) {
    notificationsStore.getState().addNotification({
      id: 'error-message',
      title: MESSAGE.ERROR,
      color: 'danger',
    })
  }

  return undefined
}

export { errorHandler }
