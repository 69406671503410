import { EuiHeaderLink } from '@elastic/eui'
import { ACTION } from '@shared/config'
import { Modal } from '@shared/ui/modal'
import { FormProvider } from 'react-hook-form'

import { useAddCategory } from '../model'
import { CategoryForm } from './category-form'

const AddCategory: ReactFc = () => {
  const { isOpen, showModal, closeModal, methods, addCategory, isSubmitting } = useAddCategory()

  return (
    <>
      <EuiHeaderLink iconType="plusInCircle" color="primary" iconSide="right" onClick={showModal}>
        {ACTION.ADD_CATEGORY}
      </EuiHeaderLink>

      <FormProvider {...methods}>
        <Modal
          title={ACTION.ADD_CATEGORY}
          onClose={closeModal}
          onSubmit={addCategory}
          isOpen={isOpen}
          isSubmitting={isSubmitting}
        >
          <CategoryForm isSubmitting={isSubmitting} />
        </Modal>
      </FormProvider>
    </>
  )
}

export { AddCategory }
