import { API_QUERY_KEY, queryClient } from '@shared/api'
import { ERROR } from '@shared/config'
import { ComboBox } from '@shared/ui/form'
import { useDebounce } from '@uidotdev/usehooks'
import { useState } from 'react'
import { type RegisterOptions, useFormContext } from 'react-hook-form'

import { useAttributeOptionsApi } from '../api'
import { type AttributeProps } from './types'

const AttributeList: ReactFc<AttributeProps> = (props) => {
  const { attribute, placeholder, inputProps } = props

  // Поиск
  const [search, setSearch] = useState<string>()
  const debouncedSearch = useDebounce(search, 500)

  const registerOptions: RegisterOptions = {
    ...(attribute.is_required && { required: ERROR.ATTRIBUTE_EMPTY }),
  }

  // Получение опций для атрибута
  const attributeListOptionsQuery = useAttributeOptionsApi.listOptions({
    attribute: attribute.id,
    search: debouncedSearch,
  })

  // Создание опции для атрибута
  const { mutate: createAttributeOption, isPending } = useAttributeOptionsApi.createOption()
  const { setValue } = useFormContext()

  return (
    <ComboBox
      name={attribute.id.toString()}
      label={placeholder}
      registerOptions={registerOptions}
      inputProps={{
        options: attributeListOptionsQuery.data?.results.map((option) => {
          return {
            value: option.id,
            label: option.value,
          }
        }),
        onSearchChange: (searchLine) => {
          setSearch(searchLine)
        },
        onCreateOption: (optionLabel: string) => {
          createAttributeOption(
            {
              body: {
                attribute: attribute.id,
                value: optionLabel,
              },
            },
            {
              onSuccess: (newOption) => {
                queryClient.refetchQueries({
                  queryKey: API_QUERY_KEY.ATTRIBUTE_OPTIONS_LIST(attribute.id),
                })

                setValue(
                  attribute.id.toString(),
                  [
                    {
                      value: newOption.id,
                      label: newOption.value,
                    },
                  ],
                  {
                    shouldDirty: true,
                  },
                )
              },
            },
          )
        },
        singleSelection: true,
        isDisabled: inputProps.disabled || isPending,
        ...inputProps,
      }}
    />
  )
}

export { AttributeList }
