import { useMemo, useState } from 'react'

import { useRouterParams } from '../use-router-params'
import { type UseTabsArgs } from './types'

const useTabs = (...args: UseTabsArgs) => {
  const [tabs, defaultTab] = args
  const [selectedTabId, setSelectedTabId] = useState(defaultTab)
  const { setSearchParams } = useRouterParams()

  const selectedTabContent = useMemo(() => {
    return tabs.find((obj) => {
      return obj.id === selectedTabId
    })?.content
  }, [selectedTabId])

  const onSelectedTabChanged = (id: string) => {
    if (selectedTabId !== id) {
      setSearchParams()
      setSelectedTabId(id)
    }
  }

  return {
    selectedTabId,
    selectedTabContent,
    onSelectedTabChanged,
  }
}

export { useTabs }
