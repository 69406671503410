import { EuiFlexGroup, EuiFlexItem, EuiSpacer } from '@elastic/eui'
import { AttributeFormField } from '@entities/attributes'
import { FileCard } from '@entities/files'
import { type ApiFile, type ApiObjectAttribute } from '@shared/api/types'
import { useNotificationsStore } from '@shared/ui/notifications'

import { useSkuUpdateAttributes } from '../model'
import { SkuAttributeName } from './sku-attribute-name'

const SkuAttribute: ReactFc<ApiObjectAttribute> = (attribute) => {
  // Сохранение
  const { updateAttributeFiles, isSubmitting, isRemoving } = useSkuUpdateAttributes()

  const canLeave = useNotificationsStore.useCanLeave()
  const setOnLeave = useNotificationsStore.useSetOnLeave()

  const buildRemoveHandler = (file: ApiFile) => {
    if (canLeave) {
      return updateAttributeFiles({ attribute, file })
    }

    return () => {
      setOnLeave(updateAttributeFiles({ attribute, file }))
    }
  }

  const isFile = attribute.files

  return (
    <EuiFlexGroup alignItems="flexStart" justifyContent="spaceBetween">
      <EuiFlexItem grow={3}>
        <SkuAttributeName {...attribute} />
      </EuiFlexItem>

      <EuiFlexItem grow={7}>
        <AttributeFormField
          attribute={attribute}
          disablePlaceholder
          isCompressed
          isSubmitting={isSubmitting}
        />

        {isFile && attribute.files.length > 0 && (
          <>
            <EuiSpacer size="s" />
            <EuiFlexGroup gutterSize="s" wrap>
              {attribute.files.map((file) => {
                return (
                  <FileCard
                    key={file.id}
                    {...file}
                    onRemove={buildRemoveHandler(file)}
                    isRemoving={isRemoving}
                    isEditable={attribute.is_editable}
                  />
                )
              })}
            </EuiFlexGroup>
            <EuiSpacer />
          </>
        )}
      </EuiFlexItem>
    </EuiFlexGroup>
  )
}

export { SkuAttribute }
