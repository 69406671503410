import { EuiDatePicker, EuiDatePickerRange } from '@elastic/eui'
import { DATE_FORMAT } from '@shared/config'
import moment from 'moment'
import { useFormContext } from 'react-hook-form'

import { type FilterFields } from '../types'
import { dateRangeWidth } from './style'
import { type DateRangeFilterProps } from './types'

const DateRangeFilter: ReactFc<DateRangeFilterProps> = ({ paramPrefix, paramSuffixes }) => {
  const { setValue, watch } = useFormContext<FilterFields>()

  const currentField = watch(`filters.${paramPrefix}`)
  const startDate = moment(currentField?.filterParams[0]?.value)
  const endDate = moment(currentField?.filterParams[1]?.value)

  const handleDateChange = (date: moment.Moment | null, isStartDate: boolean) => {
    const newStartDate = isStartDate ? moment(date) : startDate
    const newEndDate = isStartDate ? endDate : moment(date)

    setValue(`filters.${paramPrefix}.filterParams`, [
      {
        param: paramPrefix + paramSuffixes.after,
        value: newStartDate.format(DATE_FORMAT),
      },
      {
        param: paramPrefix + paramSuffixes.before,
        value: newEndDate.format(DATE_FORMAT),
      },
    ])
  }

  return (
    <EuiDatePickerRange
      startDateControl={
        <EuiDatePicker
          selected={startDate}
          startDate={startDate}
          endDate={endDate}
          onChange={(date) => {
            return handleDateChange(date, true)
          }}
          compressed
        />
      }
      endDateControl={
        <EuiDatePicker
          selected={endDate}
          startDate={startDate}
          endDate={endDate}
          onChange={(date) => {
            return handleDateChange(date, false)
          }}
          compressed
        />
      }
      compressed
      css={dateRangeWidth}
    />
  )
}

export { DateRangeFilter }
