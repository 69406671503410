import { API_ATTRIBUTE_TYPE } from '@shared/api'

import { type AttributeTypeMap } from './types'

export const ATTRYBUTE_TYPE_MAP: AttributeTypeMap = {
  [API_ATTRIBUTE_TYPE.TEXT]: 'Текст короткий',
  [API_ATTRIBUTE_TYPE.LIST]: 'Список',
  [API_ATTRIBUTE_TYPE.BOOL]: 'Логический',
  [API_ATTRIBUTE_TYPE.NESTED_LIST]: 'Вложенный список',
  [API_ATTRIBUTE_TYPE.NUMBER]: 'Число целое',
  [API_ATTRIBUTE_TYPE.FILE]: 'Файл',
  [API_ATTRIBUTE_TYPE.JSON]: 'JSON',
  [API_ATTRIBUTE_TYPE.DATE]: 'Дата',
  [API_ATTRIBUTE_TYPE.FLOAT]: 'Число дробное',
  [API_ATTRIBUTE_TYPE.LONG_TEXT]: 'Текст',
}

export const ATTRIBUTE_TYPE_VALUE_MAP: AttributeTypeMap = {
  [API_ATTRIBUTE_TYPE.TEXT]: 'value',
  [API_ATTRIBUTE_TYPE.LIST]: 'option',
  [API_ATTRIBUTE_TYPE.BOOL]: 'bool',
  [API_ATTRIBUTE_TYPE.NESTED_LIST]: '', // TODO: Добавить, как будет в бекенде
  [API_ATTRIBUTE_TYPE.NUMBER]: 'number',
  [API_ATTRIBUTE_TYPE.FILE]: 'files',
  [API_ATTRIBUTE_TYPE.JSON]: 'json',
  [API_ATTRIBUTE_TYPE.DATE]: 'datetime',
  [API_ATTRIBUTE_TYPE.FLOAT]: 'float',
  [API_ATTRIBUTE_TYPE.LONG_TEXT]: 'long_text',
}
