import { convertSelectToPopoverOptions } from '@shared/lib'

import { useAttributeGroupsList } from '../model'
import { attributeTypeOptions } from './attribute-type-options'

const useAttributeFilterOptions = () => {
  const { data: groupData } = useAttributeGroupsList()

  const groupOptions = groupData.map(({ id, name }) => {
    return {
      value: id,
      inputDisplay: name,
    }
  })

  const filterTypeOptions = convertSelectToPopoverOptions(attributeTypeOptions)
  const filterGroupOptions = convertSelectToPopoverOptions(groupOptions)

  return { typeOptions: filterTypeOptions, groupOptions: filterGroupOptions }
}

export { useAttributeFilterOptions }
