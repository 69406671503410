import { createSelectorHooks } from 'auto-zustand-selectors-hook'
import { type ReactNode } from 'react'
import { create } from 'zustand'

import { type BreadcrumbsInitialValues, type BreadcrumbsStore } from './types'

const initialValues: BreadcrumbsInitialValues = {
  renderAppend: null,
}

const breadcrumbsStore = create<BreadcrumbsStore>()((set) => {
  return {
    ...initialValues,

    setRenderAppend: (renderAppend: () => ReactNode) => {
      return set({ renderAppend })
    },

    reset: () => {
      return set(initialValues)
    },
  }
})

const useBreadcrumbsStore = createSelectorHooks(breadcrumbsStore)

export { breadcrumbsStore, useBreadcrumbsStore }
