import { EuiForm } from '@elastic/eui'
import { LABEL, MESSAGE } from '@shared/config'
import { ComboBox, FormError, SelectInput, TextInput } from '@shared/ui/form'

import { useCategoryForm } from '../model'
import { type CategoryFormProps } from './types'

const CategoryForm: ReactFc<CategoryFormProps> = ({ id, isSubmitting }) => {
  const { isCatalogOptionsLoading, catalogOptions, isCategoryOptionsLoading, categoryOptions } =
    useCategoryForm(id)

  return (
    <EuiForm component="form">
      <TextInput
        name="name"
        placeholder={LABEL.CATEGORY_NAME}
        registerOptions={{ required: MESSAGE.ENTER_NAME }}
        inputProps={{
          disabled: isSubmitting,
        }}
        isRequired
      />
      <ComboBox
        name="object_group"
        placeholder={MESSAGE.SELECT_CATALOG}
        label={LABEL.CATALOG}
        inputProps={{
          options: catalogOptions,
          isLoading: isCatalogOptionsLoading,
        }}
        registerOptions={{ required: MESSAGE.SELECT_CATALOG }}
      />
      <SelectInput
        name="parent"
        placeholder={MESSAGE.ENTER_CATEGORY}
        label={LABEL.PARENT_CATEGORY}
        inputProps={{
          options: categoryOptions,
          isLoading: isCategoryOptionsLoading,
        }}
      />

      <FormError />
    </EuiForm>
  )
}

export { CategoryForm }
