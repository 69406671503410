import { EuiFlexGroup, EuiFlexItem, EuiIcon, EuiSpacer, EuiText, EuiToolTip } from '@elastic/eui'
import { ATTRIBUTE_TYPE_HELP } from '@entities/attributes/lib/attribute-type-help'
import { type ApiObjectAttribute } from '@shared/api/types'
import { ATTRIBUTE_FIELD_NAMES } from '@shared/config'
import { InfoTooltip } from '@shared/ui'
import { useStyles } from '@shared/ui/styles'

import { getStyles } from './styles'

const SkuAttributeName: ReactFc<ApiObjectAttribute> = (attribute) => {
  const styles = useStyles(getStyles)

  const name = (
    <EuiFlexGroup alignItems="center" gutterSize="none" css={styles.name}>
      {attribute.is_required && (
        <EuiFlexItem grow={false} css={styles.required}>
          <EuiToolTip position="top" content={ATTRIBUTE_FIELD_NAMES.is_required}>
            <EuiIcon type="asterisk" size="m" color="warning" />
          </EuiToolTip>
        </EuiFlexItem>
      )}
      <EuiFlexItem>{attribute.name}</EuiFlexItem>
    </EuiFlexGroup>
  )

  const tooltipText = (
    <>
      {attribute.description && (
        <>
          <EuiText size="s">{attribute.description}</EuiText>
          <EuiSpacer size="s" />
        </>
      )}
      <EuiText size="s">{ATTRIBUTE_TYPE_HELP[attribute.attribute_type!].TOOLTIP}</EuiText>
    </>
  )

  return (
    <InfoTooltip tooltip={tooltipText} color="GrayText">
      {name}
    </InfoTooltip>
  )
}

export { SkuAttributeName }
