import { PAGE_TITLES } from '@shared/config'
import { useMeta } from '@shared/hooks'
import { Layout } from '@shared/ui/layouts'
import { ImportFilesHeader, ImportFilesList } from '@widgets/import'

const ImportPage: ReactFc = () => {
  useMeta({ title: PAGE_TITLES.IMPORT.title })

  return (
    <Layout header={<ImportFilesHeader />}>
      <ImportFilesList />
    </Layout>
  )
}

export { ImportPage }
