import { EuiFlexGroup, EuiFlexItem, EuiForm } from '@elastic/eui'
import { PasswordComponent, useChangeUserPassword } from '@entities/user'
import { ACTION } from '@shared/config'
import { FormError } from '@shared/ui/form'
import { Modal } from '@shared/ui/modal'
import { FormProvider } from 'react-hook-form'

import { type ChangeUserPasswordModalProps } from '../types'

const ChangeUserPasswordModal: ReactFc<ChangeUserPasswordModalProps> = ({
  isOpen,
  onClose,
  userId,
}) => {
  const { methods, isUserSubmitting, handleModalClose, handleChangeUserPassword } =
    useChangeUserPassword({ onClose, userId })

  const getPasswordValue = () => {
    return methods.getValues('password')
  }

  return (
    <FormProvider {...methods}>
      <Modal
        title={ACTION.CHANGE_PASSWORD}
        onClose={handleModalClose}
        onSubmit={methods.handleSubmit(handleChangeUserPassword)}
        isOpen={isOpen}
        isSubmitting={isUserSubmitting}
        submitButtonText={ACTION.SAVE}
      >
        <EuiForm component="form">
          <EuiFlexGroup direction="column" gutterSize="s">
            <PasswordComponent
              isSubmitting={isUserSubmitting}
              getPasswordValue={getPasswordValue}
            />
            <EuiFlexItem>
              <FormError />
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiForm>
      </Modal>
    </FormProvider>
  )
}

export { ChangeUserPasswordModal }
