import { EuiForm } from '@elastic/eui'
import { useUpdateUserApi } from '@entities/users'
import { API_QUERY_KEY, queryClient } from '@shared/api'
import { ACTION, ELEMENTS_L_WIDTH, LABEL } from '@shared/config'
import { TransferList } from '@shared/ui'
import { Modal } from '@shared/ui/modal'
import { FormProvider, useForm } from 'react-hook-form'

import { type PermissionsEditorModalProps, type PermissionsFieldValues } from './types'

const PermissionsEditorModal: ReactFc<PermissionsEditorModalProps> = ({
  userRoles,
  onClose,
  isOpen,
  roles,
  userId,
}) => {
  const { mutate: updateUserPermissions, isPending: isUserPermissionsSubmitting } =
    useUpdateUserApi()

  const methods = useForm<PermissionsFieldValues>()

  const selectableListItems = roles?.map(({ id, name }) => {
    return {
      name: id.toString(),
      label: name,
      isChecked: false,
    }
  })

  const selectedItems = selectableListItems?.filter(({ name }) => {
    return userRoles?.includes(parseInt(name))
  })

  const handleSuccess = () => {
    queryClient.refetchQueries({ queryKey: API_QUERY_KEY.USER(userId) })
    onClose()
  }

  const handleSubmit = ({ permissions }: PermissionsFieldValues) => {
    const groups = permissions.selected.map(({ name }) => {
      return parseInt(name)
    })

    updateUserPermissions(
      {
        params: {
          path: {
            id: userId,
          },
        },
        body: { groups },
      },
      { onSuccess: handleSuccess },
    )
  }

  return (
    <FormProvider {...methods}>
      <Modal
        title={LABEL.PERMISSIONS}
        onClose={onClose}
        onSubmit={methods.handleSubmit(handleSubmit)}
        isOpen={isOpen}
        isSubmitting={isUserPermissionsSubmitting}
        submitButtonText={ACTION.SAVE}
        maxWidth={ELEMENTS_L_WIDTH}
      >
        <EuiForm component="form" fullWidth>
          <TransferList
            name="permissions"
            itemsList={selectableListItems}
            selectedItems={selectedItems}
          />
        </EuiForm>
      </Modal>
    </FormProvider>
  )
}

export { PermissionsEditorModal }
