import { EuiPanel, EuiText } from '@elastic/eui'
import { useAutomaticTaskApi } from '@entities/automatic_tasks'
import { ACTION, MESSAGE } from '@shared/config'
import { InfoPageCategory } from '@shared/ui'
import { FormProvider } from 'react-hook-form'
import { useParams } from 'react-router'

import { AutomaticTaskInfoRows } from './automatic-task-info-rows'
import { type AutomaticTaskInfoProps } from './types'

const AutomaticTaskInfo: ReactFc<AutomaticTaskInfoProps> = ({ methods, isTaskSubmitting }) => {
  const { id } = useParams()
  const taskId = parseInt(id!)
  const { data: task, isLoading: isTaskLoading } = useAutomaticTaskApi(taskId)

  // TODO: добавить скелетон
  if (!task || isTaskLoading) {
    return (
      <EuiPanel>
        <EuiText textAlign="center">{MESSAGE.EMPTY_DATA}</EuiText>
      </EuiPanel>
    )
  }

  return (
    <EuiPanel color="subdued" borderRadius="none" paddingSize="none">
      <FormProvider {...methods}>
        <InfoPageCategory id={taskId} categoryName={ACTION.ALL_FIELDS}>
          <AutomaticTaskInfoRows id={taskId} isSubmitting={isTaskSubmitting} />
        </InfoPageCategory>
      </FormProvider>
    </EuiPanel>
  )
}

export { AutomaticTaskInfo }
