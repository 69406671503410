import { EuiHeaderLink } from '@elastic/eui'
import { ACTION } from '@shared/config'

import { useRemoveAttributeOptions } from '../model'

const RemoveAttributeOptions: ReactFc = () => {
  const { isDisabledButton, isSubmitting, removeOptionsHandler } = useRemoveAttributeOptions()

  return (
    <EuiHeaderLink
      iconType="trash"
      color="danger"
      iconSide="right"
      onClick={removeOptionsHandler}
      isLoading={isSubmitting}
      disabled={isDisabledButton}
    >
      {ACTION.DELETE_SELECTED}
    </EuiHeaderLink>
  )
}

export { RemoveAttributeOptions }
