import { EuiHeader, EuiHeaderSection, EuiHeaderSectionItem } from '@elastic/eui'
import { noShadow } from '@shared/ui/styles'

import { ImportFilesButton } from './import-files-button'

const ImportFilesHeader = () => {
  return (
    <EuiHeader css={noShadow}>
      <EuiHeaderSection side="right">
        <EuiHeaderSectionItem>
          <ImportFilesButton />
        </EuiHeaderSectionItem>
      </EuiHeaderSection>
    </EuiHeader>
  )
}

export { ImportFilesHeader }
