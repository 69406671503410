import { ACTION } from '@shared/config'
import { Modal } from '@shared/ui/modal'
import { FormProvider } from 'react-hook-form'

import { useUpdateCategory } from '../model'
import { CategoryForm } from './category-form'
import { type UpdateCategoryModalProps } from './types'

const UpdateCategoryModal: ReactFc<UpdateCategoryModalProps> = ({ id, isOpen, closeModal }) => {
  const { methods, updateCategory, isSubmitting } = useUpdateCategory(id, closeModal)

  return (
    <FormProvider {...methods}>
      <Modal
        title={ACTION.EDIT_CATEGORY}
        submitButtonText={ACTION.SAVE}
        onClose={closeModal}
        onSubmit={updateCategory}
        isOpen={isOpen}
        isSubmitting={isSubmitting}
      >
        <CategoryForm id={id} isSubmitting={isSubmitting} />
      </Modal>
    </FormProvider>
  )
}

export { UpdateCategoryModal }
