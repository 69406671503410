import { type Toast } from '@elastic/eui/src/components/toast/global_toast_list'

import {
  type AttributeFieldNames,
  type AutomaticTaskFieldNames,
  type CategoryFieldNames,
  type ValidatorFieldNames,
} from './types'

export const APP_NAME = 'SKU HUB'
// TODO: Ссылка на публичный каталог, скорее всего будет в ENV
export const APP_PUBLIC_URL = 'https://google.com/search?q='

export const DATE_FORMAT = 'YYYY-MM-DD'
export const DATE_FORMAT_FULL = 'YYYY-MM-DD HH:MM:SS'
export const DATE_RANGE_LABEL = 'date-range'

export const SIMPLE_LAYOUT_CONTENT_WIDTH = 268
export const SIMPLE_LAYOUT_LOGO_WIDTH = 210

export const SIDEBAR_OPEN_MIN_WIDTH = 200
export const SIDEBAR_CLOSED_MIN_WIDTH = 59
export const SIDEBAR_OPEN_LOGO_WIDTH = 118
export const SIDEBAR_CLOSED_LOGO_WIDTH = 21

export const ELEMENTS_XXS_WIDTH = 160
export const ELEMENTS_XS_WIDTH = 240
export const ELEMENTS_S_WIDTH = 300
export const ELEMENTS_M_WIDTH = 400
export const ELEMENTS_L_WIDTH = 750

export const ELEMENTS_M_HEIGHT = 400

export const CATALOG_CARD_SKELETON_AMOUNT = 9
export const CATALOG_CARD_IMAGE_SIZE = 200
export const CATALOG_TABLE_IMAGE_SIZE = 50

export const DEFAULT_ITEMS_PER_PAGE = 25
export const ITEMS_PER_PAGE_OPTIONS = [DEFAULT_ITEMS_PER_PAGE, 50, 100]

export const OTHER_ATTRIBUTE_GROUP_ID = -1

export enum CATALOG_VIEW {
  CARDS = 'Сетка',
  TABLE = 'Таблица',
}

export enum LABEL {
  ACTIONS = 'Действия',
  ATTRIBUTE = 'Атрибут',
  ATTRIBUTES = 'Атрибуты',
  ATTRIBUTES_IN_CATEGORY = 'Атрибутов в категории',
  ATTRIBUTES_IN_GROUP = 'Атрибутов в группе',
  ATTRIBUTES_ROOT_CATEGORY = 'Корневая категория',
  ATTRIBUTE_GROUP = 'Группа атрибута',
  ATTRIBUTE_GROUP_NAME = 'Название группы',
  ATTRIBUTE_NAME = 'Название атрибута',
  ATTRIBUTE_TYPE = 'Тип атрибута',
  AVAILABLE = 'Доступные',
  CATALOG = 'Каталог',
  CATEGORIES = 'Категории',
  CATEGORY = 'Категория',
  CATEGORY_NAME = 'Название категории',
  CHECKED = 'Выбранные',
  CONFIRM_PASSWORD = 'Подтверждение пароля',
  CREATE_DATE = 'Дата создания',
  DAYS = 'Дни',
  DAYS_OF_WEEK = 'Дни недели',
  DRAFT = 'Черновик',
  EMAIL_LIST = 'Список электронных адресов',
  EXPORT = 'Экспорт',
  FILTERS = 'Фильтры',
  FULLNESS = 'Заполненность',
  HOURS = 'Часы',
  ID = 'ID',
  IMAGES_FILES = 'Изображения и файлы',
  LAST_30_DAYS = 'Последние 30 дней',
  LAST_MONTH = 'В прошлом месяце',
  LOGIN = 'Логин',
  MENU = 'Меню',
  MINUTES = 'Минуты',
  MONTHS = 'Месяцы',
  NAME = 'Название',
  OPTIONS_LIST = 'Список опций',
  ORDER = 'Позиция',
  OTHER = 'Прочее',
  PAGINATION = 'Навигация по страницам',
  PARENT_CATEGORY = 'Родительская категория',
  PASSWORD = 'Пароль',
  PERMISSIONS = 'Права доступа',
  PROGRESS = 'Прогресс',
  PROGRESS_0_25 = 'от 0 до 25%',
  PROGRESS_25_50 = 'от 25% до 50%',
  PROGRESS_50_75 = 'от 50% до 75%',
  PROGRESS_75_100 = 'от 75% до 100%',
  PUBLIC = 'Публичный',
  REMOVE_ALL_COLUMNS = 'Убрать все колонки',
  REMOVE_CHECKED_COLUMNS = 'Убрать отмеченные колонки',
  REPEATABILITY = 'Периодичность',
  RESOLUTION = 'Разрешение',
  SELECT_ALL_COLUMNS = 'Выбрать все колонки',
  SELECT_CHECKED_COLUMNS = 'Выбрать отмеченные колонки',
  SELECT_PERIOD = 'Выбранный период',
  SEND_NOTIFICATION = 'Отправить уведомление',
  SETTINGS = 'Настройки',
  SIZE = 'Размер',
  SKUS = 'Товары',
  SKU_IN_CATEGORY = 'Товаров в категории',
  SKU_NAME = 'Название товара',
  SKU_SKU = 'Артикул товара',
  START_DATE = 'Дата начала',
  THIS_MONTH = 'В этом месяце',
  THIS_WEEK = 'На этой неделе',
  THIS_YEAR = 'В этом году',
  TODAY = 'Сегодня',
  TRIGGER = 'Триггер',
  TYPE = 'Тип',
  UPDATE_DATE = 'Дата обновления',
  IMPORT = 'Импорт',
  USER_NAME = 'Имя пользователя',
  VALUE = 'Значение',
  VALUE_CONTAIN = 'Значение содержит',
  VALUE_EQUAL = 'Значения равны',
  VALUE_EXIST_FALSE = 'Нет значения',
  VALUE_EXIST_TRUE = 'Есть значение',
  VALUE_NOT_CONTAIN = 'Значение не содержит',
  VALUE_NOT_EQUAL = 'Значения не равны',
  YESTERDAY = 'Вчера',
  NOTIFICATION = 'Предупреждение',
}

export enum MESSAGE {
  AVAILABLE = 'Доступные',
  CATEGORY_REQUIRED = 'Не выбрана категория',
  CHECKED = 'Выбранные',
  CREATE_DATE = 'Дата создания',
  CREATED = 'Создано',
  DONE = 'Готово',
  EMPTY = 'Ничего не найдено',
  EMPTY_CATALOGS = 'Каталоги отсутствуют',
  EMPTY_DATA = 'Данные отсутствуют',
  EMPTY_FILES_LIST = 'Файлы отсутствуют',
  EMPTY_NAME = 'Без названия',
  EMPTY_VALUE = '–',
  ENTER_CATEGORY = 'Выберите категорию',
  ENTER_NAME = 'Введите название',
  FILL_FIELD = 'Заполните поле',
  ENTER_SKU = 'Введите артикул',
  ENTER_TYPE = 'Укажите тип',
  ERROR = 'Ошибка',
  EXPORT_TIME = 'Процесс экспорта может занять некоторое время, в зависимости от количества данных',
  FILE_UPLOAD = 'Перенесите сюда файл для загрузки',
  FILE_UPLOAD_CREATE_AT = 'Загружен',
  IMPORT = 'Импорт',
  IMPORT_DATE = 'Дата импорта',
  IMPORT_LOG = 'Лог импорта',
  IMPORT_TYPE = 'Тип импорта',
  IMPORT_USER = 'Инициатор импорта',
  INITIATOR = 'Инициатор',
  IS_FEATURED = 'Если включено, данный атрибут будет отображаться в карточке товара при его просмотре',
  LAST_LAUNCH = 'Дата последнего запуска',
  LOADING = 'Загрузка…',
  REMOVED = 'Удалено',
  SAVED = 'Сохранено',
  SELECT_CATALOG = 'Выберите каталог',
  TYPE = 'Тип',
  UPDATE_DATE = 'Дата обновления',
  UNSAVED_CHANGES = 'Уверены, что хотите закрыть эту страницу? Введенные данные будут утеряны.',
  ATTRIBUTE_SEARCH = 'Поиск атрибута',
}

export enum ERROR {
  ATTRIBUTE_EMPTY = 'Введите значение атрибута',
  AUTH_INCORRECT = 'Вы ввели неправильный логин или пароль',
  ENTER_LOGIN = 'Введите логин',
  ENTER_PASSWORD = 'Введите пароль',
  ENTER_USER_NAME = 'Введите имя',
  EXPORT_ERROR = 'Ошибка экспорта',
  JSON_INCORRECT = 'JSON содержит ошибки',
  NO_PAGE = 'Страница, которую вы ищете, не найдена, попробуйте начать навигацию с главной страницы',
  NOT_EMPTY = 'Нельзя удалить объект, пока в нём содержатся дочерние элементы',
  PERMISSION = 'У вас нет прав для просмотра этого раздела',
  SELECT_MIN_ONE = 'Выберите минимум один элемент',
  SERVER = 'Ошибка сервера, попробуйте ещё раз',
  USER_NAME_FORMAT = 'Имя может содержать только буквы, цифры и символы @._+-',
  USER_NAME_MAX_LENGTH = 'Имя не должно превышать 150 символов',
  USER_NAME_MIN_LENGTH = 'Имя должно содержать минимум 3 символов',
  USER_PASSWORD_MATCH = 'Пароли не совпадают',
  USER_PASSWORD_MIN_LENGTH = 'Пароль должен содержать минимум 8 символов',
  USER_PASSWORD_NUMERIC = 'Пароль не может состоять только из цифр',
}

export enum ACTION {
  ACTIONS = 'Действия',
  ADD = 'Добавить',
  ADD_ATTRIBUTE_GROUP = 'Создание группы атрибутов',
  ADD_CATEGORY = 'Создать категорию',
  ADD_GROUP = 'Создать группу',
  ADD_OPTION = 'Создать опцию',
  ADD_TASK = 'Добавить задачу',
  ALL_FIELDS = 'Все поля',
  ATTRIBUTES_CATEGORY_SELECT = 'Выбрать категорию',
  ATTRIBUTES_LINK = 'Привязать',
  BACK = 'Назад',
  BACK_MAIN = 'Вернуться на главную',
  CHANGE_PASSWORD = 'Изменить пароль',
  CLEAR = 'Очистить',
  CREATE = 'Создать',
  DELETE = 'Удалить',
  DOWNLOAD = 'Скачать',
  DELETE_SELECTED = 'Удалить выбранные',
  EDIT = 'Редактировать',
  EDIT_ATTRIBUTE = 'Редактировать атрибут',
  EDIT_ATTRIBUTE_GROUP = 'Редактирование группы атрибутов',
  EDIT_ATTRIBUTE_OPTION = 'Редактирование опции атрибута',
  EDIT_CATEGORY = 'Редактирование категории',
  EXCLUDE_SELECTED = 'Исключить выбранные',
  EXPORT = 'Экспорт',
  FIND_ATTRIBUTE = 'Найти атрибут',
  FIND_CATEGORY = 'Найти категорию',
  FIND_FILTER = 'Найти фильтр',
  FIND_GROUP = 'Найти группу',
  FIND_SKU = 'Найти товар',
  FIND_USER = 'Найти пользователя',
  LINK_TO_CATEGORY = 'Привязать к категории',
  LOGIN_BUTTON = 'Войти',
  NEW_ATTRIBUTE = 'Новый атрибут',
  NEW_TASK = 'Новая задача',
  NEW_SKU = 'Новый товар',
  NEW_USER = 'Новый пользователь',
  OBJECT_GROUP_SELECT = 'Выбрать группу',
  SAVE = 'Сохранить',
  START_EXPORT = 'Начать экспорт',
  SWITCH_USER = 'Сменить профиль',
  TYPE_SELECT = 'Выбрать тип',
  NEW_IMPORT_FILE = 'Новый файл импорта',
  SELECT_LIST = 'Выбрать список',
  SELECT_TRIGGER = 'Выбрать триггер',
  SELECT_DATE = 'Выбрать дату',
  SELECT_TEMPLATE = 'Выбрать шаблон',
  HOURLY = 'Ежечасно',
  DAILY = 'Ежедневно',
  WEEKLY = 'Еженедельно',
  MONTHLY = 'Ежемесячно',
  YEARLY = 'Ежегодно',
  ADD_FILE = 'Добавить файл',
  IMPORT = 'Импортировать',
  DOWNLOAD_TEMPLATE = 'Скачать шаблон',
  START_AFTER_VALIDATION = 'Начать импорт сразу после валидации',
  CANCEL = 'Отмена',
  SAVE_AND_LEAVE = 'Сохранить и перейти',
  LEAVE_WITHOUT_SAVE = 'Перейти без сохранения',
}

export enum ATTRIBUTE_LABEL {
  BRAND = 'Brand',
  NAME = 'PN',
  PREVIEW = 'Preview',
  SKU = 'SKU',
}

export const ATTRIBUTE_FIELD_NAMES: AttributeFieldNames = {
  uuid: 'UUID',
  is_enabled: 'Активен',
  is_deleted: 'Удален',
  name: 'Название',
  attribute_type: 'Тип',
  category: 'Категория',
  is_featured: 'Показывать в карточке',
  is_required: 'Обязательный',
  description: 'Описание',
  is_public: 'Публичный',
  object_group: 'Группа',
  is_sku: 'SKU',
  is_name: 'Название продукта',
  is_brand: 'Бренд',
  is_preview: 'Превью',
  slug: 'Slug',
  updated_at: 'Обновлен',
  created_at: 'Создан',
  created_by: 'Создатель',
}

export enum ATTRIBUTE_FIELDS {
  UUID = 'uuid',
  IS_ENABLED = 'is_enabled',
  IS_DELETED = 'is_deleted',
  NAME = 'name',
  ATTRIBUTE_TYPE = 'attribute_type',
  CATEGORY = 'category',
  IS_FEATURED = 'is_featured',
  IS_REQUIRED = 'is_required',
  DESCRIPTION = 'description',
  IS_PUBLIC = 'is_public',
  OBJECT_GROUP = 'object_group',
  IS_SKU = 'is_sku',
  IS_NAME = 'is_name',
  IS_BRAND = 'is_brand',
  IS_PREVIEW = 'is_preview',
  SLUG = 'slug',
  UPDATED_AT = 'updated_at',
  CREATED_AT = 'created_at',
  CREATED_BY = 'created_by',
}

export const VALIDATOR_FIELD_NAMES: ValidatorFieldNames = {
  uuid: 'UUID',
  is_enabled: 'Активен',
  is_deleted: 'Удален',
  use_validators: 'Использовать валидатор',
  validated_attribute_name: 'Валидируемый атрибут',
  is_required_value: 'Обязательное значение',
  block_save_with_validation_errors: 'Блокировать сохранение при ошибке валидации',
  group: 'Группа',
  updated_at: 'Обновлен',
  created_at: 'Создан',
  created_by: 'Создатель',
  weight: 'Вес',
  min_length: 'Минимальная длина',
  max_length: 'Максимальная длина',
  min_numeric_value: 'Минимальное значение',
  max_numeric_value: 'Максимальное значение',
  forbidden_words: 'Запрещенные слова',
  validated_attribute: 'Валидируемый атрибут',
  id: 'ID',
}

export enum VALIDATOR_FIELDS {
  UUID = 'uuid',
  IS_ENABLED = 'is_enabled',
  IS_DELETED = 'is_deleted',
  IS_REQUIRED_VALUE = 'is_required_value',
  USE_VALIDATORS = 'use_validators',
  VALIDATORS_ATTRIBUTE_NAME = 'validated_attribute_name',
  WEIGHT = 'weight',
  BLOCK_SAVE_WITH_VALIDATION_ERRORS = 'block_save_with_validation_errors',
  MIN_LENGTH = 'min_length',
  MAX_LENGTH = 'max_length',
  MIN_NUMERIC_VALUE = 'min_numeric_value',
  MAX_NUMERIC_VALUE = 'max_numeric_value',
  FORBIDDEN_WORDS = 'forbidden_words',
  VALIDATED_ATTRIBUTE = 'validated_attribute',
  GROUP = 'group',
  UPDATED_AT = 'updated_at',
  CREATED_AT = 'created_at',
  CREATED_BY = 'created_by',
  ID = 'id',
}

export const CATEGORY_FIELD_NAMES: CategoryFieldNames = {
  id: 'ID',
  skus_count: 'Количество SKU',
  attributes_count: 'Количество атрибутов',
  is_editable: 'Редактируемый',
  created_by: 'Создатель',
  uuid: 'UUID',
  created_at: 'Создан',
  updated_at: 'Обновлен',
  is_enabled: 'Активен',
  is_deleted: 'Удален',
  group: 'Группа',
  parent: 'Родительская категория',
  object_group: 'Каталоги',
}

export enum CATEGORY_FIELDS {
  ID = 'id',
  SKUS_COUNT = 'skus_count',
  ATTRIBUTES_COUNT = 'attributes_count',
  IS_EDITABLE = 'is_editable',
  CREATED_BY = 'created_by',
  UUID = 'uuid',
  CREATED_AT = 'created_at',
  UPDATED_AT = 'updated_at',
  IS_ENABLED = 'is_enabled',
  IS_DELETED = 'is_deleted',
  GROUP = 'group',
  PARENT = 'parent',
  OBJECT_GROUP = 'object_group',
}

export enum PARAMS {
  AFTER = '_after',
  ATTRIBUTE_AFTER = '__after',
  ATTRIBUTE_BEFORE = '__before',
  ATTRIBUTE_TYPE = 'attribute_type',
  ATTRIBUTE_VALUE = 'attribute_value',
  ATTRIBUTE_VALUE_CONTAIN = '__contain',
  ATTRIBUTE_VALUE_EQUAL = '__equal',
  ATTRIBUTE_VALUE_ISNULL = '__isnull',
  ATTRIBUTE_VALUE_NOT_CONTAIN = '__not_contain',
  ATTRIBUTE_VALUE_NOT_EQUAL = '__not_equal',
  BEFORE = '_before',
  CATEGORIES = 'categories',
  CATEGORY = 'category',
  CATEGORY_ISNULL = 'category__isnull',
  FILTERS = 'filters',
  MODEL = 'model',
  OBJECT_GROUP = 'object_group',
  OBJECT_GROUPS = 'object_groups',
  OBJECT_GROUP_ISNULL = 'object_group__isnull',
  ORDERING = 'ordering',
  ORDER_BY = 'order_by',
  PAGE = 'page',
  PROGRESS_MAX = 'progress_max',
  PROGRESS_MIN = 'progress_min',
  PUBLIC = 'is_public',
  SEARCH = 'search',
  SIZE = 'size',
  IS_NAME = 'is_name',
  IS_SKU = 'is_sku',
}

// Параметры, которые нужно проверять для фильтрации в каталоге
export const SKU_FILTERS_PARAMS = [
  PARAMS.AFTER,
  PARAMS.ATTRIBUTE_VALUE_CONTAIN,
  PARAMS.ATTRIBUTE_VALUE_EQUAL,
  PARAMS.ATTRIBUTE_VALUE_ISNULL,
  PARAMS.ATTRIBUTE_VALUE_NOT_CONTAIN,
  PARAMS.ATTRIBUTE_VALUE_NOT_EQUAL,
  PARAMS.BEFORE,
  PARAMS.PROGRESS_MAX,
  PARAMS.PROGRESS_MIN,
  PARAMS.PUBLIC,
]

export enum FILE_UPLOAD_ALLOWED {
  IMAGE = '.jpg, .jpeg, .png',
  DOCUMENTS = '.jpg, .jpeg, .png, .pdf, .doc, .docx, .xls, .xlsx, .txt, .ppt, .pptx, .mp4, .avi, .mov, .wmv, .mkv, .zip, .rar, .csv, .xml, .json',
}

export enum SKU {
  PROGRESS = 'progress',
  PUBLIC = 'public',
}

export const NOTIFICATIONS: Record<string, Toast> = {
  ATTRIBUTE_CREATE_SUCCESS: {
    id: `attribute-create-success`,
    title: MESSAGE.CREATED,
    text: 'Атрибут успешно создан',
    color: 'success',
  },
  ATTRIBUTE_GROUP_CREATE_SUCCESS: {
    id: 'attribute-group-create-success',
    title: MESSAGE.CREATED,
    text: 'Группа атрибутов успешно создана',
    color: 'success',
  },
  ATTRIBUTE_GROUP_REMOVE_SUCCESS: {
    id: 'attribute-group-remove-success',
    title: MESSAGE.REMOVED,
    text: 'Группа атрибутов успешно удалена',
    color: 'success',
  },
  ATTRIBUTE_GROUP_UPDATE_SUCCESS: {
    id: 'attribute-group-update-success',
    title: MESSAGE.SAVED,
    text: 'Группа атрибутов успешно обновлена',
    color: 'success',
  },
  ATTRIBUTE_OPTION_CREATE_SUCCESS: {
    id: 'attribute-щзешщт-create-success',
    title: MESSAGE.CREATED,
    text: 'Опция атрибута успешно создана',
    color: 'success',
  },
  ATTRIBUTE_OPTIONS_REMOVE_SUCCESS: {
    id: 'attribute-option-remove-success',
    title: MESSAGE.REMOVED,
    text: 'Опции атрибута успешно удалены',
    color: 'success',
  },
  ATTRIBUTE_OPTIONS_UPDATE_SUCCESS: {
    id: 'attribute-option-update-success',
    title: MESSAGE.SAVED,
    text: 'Опция атрибута успешно обновлена',
    color: 'success',
  },
  ATTRIBUTE_UPDATE_SUCCESS: {
    id: 'attribute-update-success',
    title: MESSAGE.SAVED,
    text: 'Атрибут успешно обновлен',
    color: 'success',
  },
  CATEGORY_CREATE_SUCCESS: {
    id: `category-create-success`,
    title: MESSAGE.CREATED,
    text: 'Категория успешно создана',
    color: 'success',
  },
  CATEGORY_REMOVE_SUCCESS: {
    id: 'category-remove-success',
    title: MESSAGE.REMOVED,
    text: 'Категория успешно удалена',
    color: 'success',
  },
  CATEGORY_UPDATE_SUCCESS: {
    id: 'category-update-success',
    title: MESSAGE.SAVED,
    text: 'Категория успешно обновлена',
    color: 'success',
  },
  NOT_FOUND: {
    id: 'server-404-error',
    title: MESSAGE.ERROR,
    text: 'Не найдено',
    color: 'danger',
  },
  PERMISSIONS_ERROR: {
    id: 'permissions-error',
    title: MESSAGE.ERROR,
    text: ERROR.PERMISSION,
    color: 'danger',
  },
  SERVER_ERROR: {
    id: 'server-error',
    title: MESSAGE.ERROR,
    text: 'Произошла ошибка на сервере, попробуйте ещё раз',
    color: 'danger',
  },
  SKU_CREATE_SUCCESS: {
    id: 'sku-create-success',
    title: MESSAGE.CREATED,
    text: 'Товар успешно создан',
    color: 'success',
  },
  SKU_FORM_ERROR: {
    id: 'sku-update-error',
    title: MESSAGE.ERROR,
    text: 'Проверьте, все ли поля формы заполнены корректно',
    color: 'danger',
  },
  SKU_UPDATE_ATTRIBUTES_SUCCESS: {
    id: 'sku-update-success',
    title: MESSAGE.SAVED,
    text: 'Атрибуты успешно обновлены',
    color: 'success',
  },
  TASK_CREATE_SUCCESS: {
    id: `task-create-success`,
    title: MESSAGE.CREATED,
    text: 'Задача успешно создана',
    color: 'success',
  },
  TASK_REMOVE_SUCCESS: {
    id: 'task-remove-success',
    title: MESSAGE.REMOVED,
    text: 'Задача успешно удалена',
    color: 'success',
  },
  TASK_UPDATE_SUCCESS: {
    id: 'task-update-success',
    title: MESSAGE.SAVED,
    text: 'Задача успешно обновлена',
    color: 'success',
  },
  USER_CREATE_SUCCESS: {
    id: `user-create-success`,
    title: MESSAGE.CREATED,
    text: 'Пользователь успешно создан',
    color: 'success',
  },
  VALIDATOR_UPDATE_SUCCESS: {
    id: 'validators-update-success',
    title: MESSAGE.SAVED,
    text: 'Валидатор успешно обновлен',
    color: 'success',
  },
}

export const TRANSLATE_MAPPING = {
  ru: {
    'euiTablePagination.rowsPerPage': 'Элементов на странице',
    'euiTablePagination.rowsPerPageOption': '{rowsPerPage}',
    'euiComboBoxOptionsList.createCustomOption': 'Добавить {searchValue} как новую опцию',
    'euiComboBoxOptionsList.allOptionsSelected': 'Вы выбрали все опции',
    'euiComboBoxOptionsList.noAvailableOptions': 'Нет доступных опций',
    'euiGlobalToastList.clearAllToastsButtonDisplayText': 'Очистить всё',
  },
}

export enum TASK_STATUS {
  PLANNED = 10,
  IN_PROGRESS = 20,
}

export const TASK_STATUS_NAME = {
  [TASK_STATUS.PLANNED]: 'Запланирована',
  [TASK_STATUS.IN_PROGRESS]: 'В процессе',
}

export enum AUTOMATIC_TASK_TYPE {
  EXPORT_SKU = 10,
  IMPORT_SKU = 20,
}

export const AUTOMATIC_TASK_NAME = {
  [AUTOMATIC_TASK_TYPE.EXPORT_SKU]: 'Экспорт SKU',
  [AUTOMATIC_TASK_TYPE.IMPORT_SKU]: 'Импорт SKU',
}

export enum AUTOMATIC_TASK_FIELDS {
  IS_ENABLED = 'is_enabled',
  GROUP = 'group',
  NAME = 'name',
  STATUS = 'status',
  TASK_TYPE = 'task_type',
  EMAIL_LIST = 'email_list',
  IS_SEND_NOTIFICATIONS = 'is_send_notifications',
  TRIGGER = 'trigger',
  START_AT = 'start_at',
  CRONTAB_SCHEDULE = 'crontab_schedule',
  TASK_RULES = 'task_rules',
  UUID = 'uuid',
  CREATED_AT = 'created_at',
  UPDATED_AT = 'updated_at',
  CREATED_BY = 'created_by',
  LAST_LAUNCH_AT = 'last_launch_at',
}

export const AUTOMATIC_TASK_FIELDS_NAMES: AutomaticTaskFieldNames = {
  uuid: 'UUID',
  is_enabled: 'Активная',
  is_send_notifications: 'Отправить уведомления',
  created_at: 'Дата создания',
  created_by: 'Инициатор',
  crontab_schedule: 'Периодичность',
  email_list: 'Список электронных адресов',
  group: 'Группа',
  last_launch_at: 'Последний запуск',
  name: 'Название',
  start_at: 'Время начала',
  status: 'Статус',
  task_rules: 'Правила',
  task_type: 'Тип',
  trigger: 'Триггер',
  updated_at: 'Дата обновления',
}

export enum IMPORT_STATUS {
  LOADED = 10,
  VALIDATED = 20,
  IMPORTED = 30,
  PLANNED = 40,
  VALIDATION_ERROR = 50,
  IMPORT_ERROR = 60,
}

export enum VALUE_FILTER {
  ALL,
  FILLED,
  UNFILLED,
}

export const VALUE_FILTER_NAME = {
  [VALUE_FILTER.ALL]: 'Все',
  [VALUE_FILTER.FILLED]: 'Заполненные',
  [VALUE_FILTER.UNFILLED]: 'Незаполненные',
}

export enum IMPORT_TYPE {
  SKU = 10,
}
