import { addAttributesList, addOtherGroup, useAttributeGroupsList } from '@entities/attributes'
import { type AttributeGroup } from '@entities/attributes/types'
import { useSkuApi } from '@entities/catalog'
import { LABEL, SKU } from '@shared/config'
import { useParams } from 'react-router'

const useFeaturedGroupsList = () => {
  const { id: skuId } = useParams()
  const skuQuery = useSkuApi(skuId)
  const attributeGroupsQuery = useAttributeGroupsList()

  const featuredAttributes = skuQuery.data?.attributes.filter((attribute) => {
    return attribute.is_featured === true
  })

  // Добавляем прогресс бар и ставим на первую позицию
  const progressGroup = {
    id: 0,
    name: LABEL.FULLNESS,
    order: 0,
    attributes: [
      {
        id: 0,
        name: LABEL.PROGRESS,
        value: skuQuery.data?.progress.toString(),
        slug: SKU.PROGRESS,
      },
    ],
  } as AttributeGroup

  if (attributeGroupsQuery.isSuccess && featuredAttributes) {
    // Create initial groups list and filter empty groups
    let attributesGroupsList: AttributeGroup[] = addAttributesList({
      groups: attributeGroupsQuery.data,
      attributes: featuredAttributes,
    }).filter((group): group is AttributeGroup => {
      return group.attributes.length > 0
    })

    // Добавляем прогресс
    attributesGroupsList = [...attributesGroupsList, progressGroup]

    // Добавляем группу "Прочее"
    return addOtherGroup(attributesGroupsList, featuredAttributes)
  }

  return []
}

export { useFeaturedGroupsList }
