import { EuiFlexGroup, EuiPanel, EuiText } from '@elastic/eui'
import { useAutomaticTaskListApi } from '@entities/automatic_tasks'
import { MESSAGE } from '@shared/config'
import { useRouterParams } from '@shared/hooks'
import { LongCardsListSkeleton, Pagination, usePagination } from '@shared/ui'

import { AutomaticTaskCard } from './automatic-task-card/automatic-task-card'

const AutomaticTaskList: ReactFc = () => {
  const { params } = useRouterParams()
  const { data: tasksData, isLoading: isTasksLoading } = useAutomaticTaskListApi(params)

  const tasks = tasksData?.results

  const paginationProps = usePagination(tasksData?.count)

  if (isTasksLoading) {
    return <LongCardsListSkeleton />
  }

  return (
    <>
      <EuiPanel borderRadius="none" color="transparent">
        <EuiFlexGroup direction="column" gutterSize="m">
          {tasks?.map((task) => {
            return <AutomaticTaskCard key={task.uuid} {...task} />
          })}

          {!tasks?.length && (
            <EuiPanel>
              <EuiText textAlign="center">{MESSAGE.EMPTY_DATA}</EuiText>
            </EuiPanel>
          )}
        </EuiFlexGroup>
      </EuiPanel>

      <Pagination {...paginationProps} />
    </>
  )
}

export { AutomaticTaskList }
