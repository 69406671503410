import _ from 'lodash'

const getDirtyFields = <T>(firstObj: T, secondObj: T) => {
  const dirtyFields: Partial<T> = {}

  for (const key in secondObj) {
    if (_.has(secondObj, key) && !_.isEqual(firstObj[key], secondObj[key])) {
      dirtyFields[key] = secondObj[key]
    }
  }

  return dirtyFields
}

export { getDirtyFields }
