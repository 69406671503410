import { ATTRIBUTE_FLAGS, extractAttribute } from '@shared/api'
import * as config from '@shared/config'

import { useCategoriesListApi } from '../api'
import { type UseCategoryListApiQueryParams } from '../api'
import { type Category } from '../types'

const useCategoriesList = (queryArgs: UseCategoryListApiQueryParams = {}) => {
  const categoriesQuery = useCategoriesListApi({
    ...queryArgs,
  })

  const data = categoriesQuery.data?.results.map((category) => {
    const name = extractAttribute(category, ATTRIBUTE_FLAGS.NAME)?.value

    const href = config.ROUTES.SETTINGS.CATEGORIZATION.CATEGORY.$buildPath({
      params: { id: category.id },
    })

    return {
      ...category,
      name,
      href,
    }
  }) as Category[]

  return {
    ...categoriesQuery,
    data,
    count: categoriesQuery.data?.count,
  }
}

export { useCategoriesList }
