import { EuiHeader, EuiHeaderSection, EuiHeaderSectionItem } from '@elastic/eui'
import { DeleteAutomaticTask } from '@features/automatic-tasks'
import { SaveButton } from '@shared/ui/form'
import { noShadow } from '@shared/ui/styles'

import { type AutomaticTaskHeaderProps } from './types'

const AutomaticTaskHeader: ReactFc<AutomaticTaskHeaderProps> = ({
  updateAutomaticTask,
  isAutomaticTaskSubmitting,
  isSaveButtonActive,
}) => {
  return (
    <EuiHeader css={noShadow}>
      <EuiHeaderSection side="right">
        <EuiHeaderSectionItem>
          <DeleteAutomaticTask />
        </EuiHeaderSectionItem>
        {isSaveButtonActive && (
          <EuiHeaderSectionItem>
            <SaveButton
              isActive={isSaveButtonActive}
              onClick={updateAutomaticTask}
              isLoading={isAutomaticTaskSubmitting}
            />
          </EuiHeaderSectionItem>
        )}
      </EuiHeaderSection>
    </EuiHeader>
  )
}

export { AutomaticTaskHeader }
