import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiPanel,
  EuiSkeletonRectangle,
  EuiSpacer,
  EuiText,
} from '@elastic/eui'
import { useSkuApi } from '@entities/catalog'
import { DATE_FORMAT_FULL, ELEMENTS_M_WIDTH, LABEL, MESSAGE } from '@shared/config'
import { useStyles } from '@shared/ui/styles'
import moment from 'moment'
import { useParams } from 'react-router'

import { getStyles } from './styles'

const SkuDates = () => {
  const { id: skuIdParam = '' } = useParams()
  const { data, isLoading } = useSkuApi(skuIdParam)
  const styles = useStyles(getStyles)

  const createdAt = !isLoading
    ? moment(data?.created_at).format(DATE_FORMAT_FULL)
    : MESSAGE.EMPTY_VALUE

  const updatedAt = !isLoading
    ? moment(data?.updated_at).format(DATE_FORMAT_FULL)
    : MESSAGE.EMPTY_VALUE

  return (
    <EuiSkeletonRectangle width={ELEMENTS_M_WIDTH} isLoading={isLoading} css={styles.dates}>
      <EuiPanel paddingSize="xs" color="transparent" hasShadow={false}>
        <EuiFlexGroup alignItems="center" gutterSize="m">
          <EuiFlexItem grow={false}>
            <EuiText size="s">
              {LABEL.CREATE_DATE}: {createdAt}
            </EuiText>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiText size="s">
              {LABEL.UPDATE_DATE}: {updatedAt}
            </EuiText>
          </EuiFlexItem>
        </EuiFlexGroup>
        <EuiSpacer size="xs" />
      </EuiPanel>
    </EuiSkeletonRectangle>
  )
}

export { SkuDates }
