import { $api } from '@shared/api'

import { type SkuListQuery } from '../types'

export const useSkuListApi = (args: SkuListQuery = {}) => {
  return $api.useQuery('get', '/skus/', {
    params: {
      query: {
        ...args,
      },
    },
  })
}
