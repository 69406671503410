import { EuiFlexGroup, EuiPanel, EuiText } from '@elastic/eui'
import { useExportFilesListApi } from '@entities/export-files'
import { MESSAGE } from '@shared/config'
import { useRouterParams } from '@shared/hooks'
import { Pagination, usePagination } from '@shared/ui'

import { ExportFilesCard } from './export-files-card'
import { ExportFilesListSkeleton } from './export-files-list-skeleton'

const ExportFilesList: ReactFc = () => {
  const { params } = useRouterParams()
  const { data: filesData, isLoading } = useExportFilesListApi({ ...params })
  const files = filesData?.results
  const paginationProps = usePagination(filesData?.count)

  if (isLoading) {
    return <ExportFilesListSkeleton />
  }

  return (
    <>
      <EuiPanel borderRadius="none" color="transparent">
        <EuiFlexGroup direction="column" gutterSize="m">
          {files?.map((file) => {
            return <ExportFilesCard key={file.uuid} {...file} />
          })}

          {!files?.length && (
            <EuiPanel>
              <EuiText textAlign="center">{MESSAGE.EMPTY_FILES_LIST}</EuiText>
            </EuiPanel>
          )}
        </EuiFlexGroup>
      </EuiPanel>

      <Pagination {...paginationProps} />
    </>
  )
}

export { ExportFilesList }
