import { EuiFlexGroup, EuiHeader, EuiHeaderSection } from '@elastic/eui'
import { noShadow } from '@shared/ui/styles'

import { AddAutomaticTask } from '../add-automatic-task'

const AutomaticTasksHeader: ReactFc = () => {
  return (
    <EuiHeader css={noShadow}>
      <EuiHeaderSection side="right">
        <EuiFlexGroup alignItems="center">
          <AddAutomaticTask />
        </EuiFlexGroup>
      </EuiHeaderSection>
    </EuiHeader>
  )
}

export { AutomaticTasksHeader }
