import { EuiHeaderLink } from '@elastic/eui'
import { ACTION } from '@shared/config'
import { useDialog } from '@shared/hooks'

import { UpdateAttributeGroupModal } from './update-attribute-group-modal'

export const UpdateAttributeGroup: ReactFc<ItemWithId> = ({ id }) => {
  const { isOpen, handleOpenDialog: showModal, handleCloseDialog: closeModal } = useDialog()

  return (
    <>
      <EuiHeaderLink
        iconType="gear"
        color="primary"
        textProps={false}
        aria-label={ACTION.EDIT}
        onClick={showModal}
      />

      <UpdateAttributeGroupModal id={id} isOpen={isOpen} closeModal={closeModal} />
    </>
  )
}
