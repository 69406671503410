import { css } from '@emotion/react'
import { ELEMENTS_M_HEIGHT, ELEMENTS_S_WIDTH } from '@shared/config'
import { type GetStyles } from '@shared/ui/styles'

export const getStyles: GetStyles = () => {
  return {
    list: css`
      min-width: ${ELEMENTS_S_WIDTH}px;
      max-height: ${ELEMENTS_M_HEIGHT}px;
      overflow-y: auto;
    `,

    searchWrapper: css`
      padding-bottom: 0;
    `,
  }
}
