import { useAddImportFilesApi } from '@entities/import-files'
import { API_QUERY_KEY, queryClient } from '@shared/api'
import { type ApiImportFile } from '@shared/api/types'
import { useForm } from 'react-hook-form'

import { type AddImportFileFormInputs, type UseAddImportFilesArgs } from '../types'

const useAddImportFile = ({ onClose, onSuccessCallback }: UseAddImportFilesArgs) => {
  const methods = useForm<AddImportFileFormInputs>()

  const { mutate: addImportFile, isPending: isImportFileSubmitting } = useAddImportFilesApi()

  const onSuccess = () => {
    queryClient.refetchQueries({ queryKey: API_QUERY_KEY.IMPORT_FILES })
    methods.reset()
    onClose()

    if (onSuccessCallback) {
      onSuccessCallback()
    }
  }

  const handleAddUser = methods.handleSubmit(async (fileImportData: AddImportFileFormInputs) => {
    const { file_upload, import_type, start_now = false } = fileImportData
    const file = file_upload[0]

    const formData = new FormData()

    formData.append('file_upload', file, file.name)
    formData.append('import_type', import_type.toString())
    formData.append('start_now', start_now.toString())

    addImportFile({ body: formData as unknown as ApiImportFile }, { onSuccess })
  })

  const handleModalClose = () => {
    methods.reset()
    onClose()
  }

  return {
    methods,
    handleAddUser,
    handleModalClose,
    isSubmitting: isImportFileSubmitting,
  }
}

export { useAddImportFile }
