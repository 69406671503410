import { $api } from '@shared/api'

const useAutomaticTaskApi = (id: number) => {
  return $api.useQuery('get', `/automatic_task/{id}/`, {
    params: {
      path: { id },
    },
  })
}

export { useAutomaticTaskApi }
