import { useUpdateObjectAttributes } from '@entities/attributes'
import { useCategoryApi, useUpdateCategoryApi } from '@entities/categories'
import { API_QUERY_KEY, queryClient } from '@shared/api'
import { NOTIFICATIONS } from '@shared/config'
import { type CategoryFieldNames } from '@shared/config/types'
import { useNotificationsStore } from '@shared/ui/notifications'
import { useParams } from 'react-router'

import { type UseUpdateCategoryArgs } from './types'

const useUpdateCategory = ({ changed, watchedFields }: UseUpdateCategoryArgs) => {
  const { id: categoryIdParam = '' } = useParams()
  const categoryId = parseInt(categoryIdParam)
  const { data: category } = useCategoryApi(categoryId)
  const addNotification = useNotificationsStore.useAddNotification()

  // Разделяем на 2 массива, если число, то это атрибут
  const attributes = changed
    .filter((item) => {
      return !isNaN(Number(item))
    })
    .map((item) => {
      return parseInt(item)
    })

  const categoryFields = changed.filter((item) => {
    return isNaN(Number(item))
  })

  // Обновляем данные о категории при успешной отправке
  const onSuccess = () => {
    queryClient.refetchQueries({ queryKey: API_QUERY_KEY.CATEGORY(categoryId) })
    addNotification(NOTIFICATIONS.CATEGORY_UPDATE_SUCCESS)
  }

  // Обновление атрибутов категории
  const { handleUpdateAttribute, isSubmitting: isSubmittingAttributes } =
    useUpdateObjectAttributes(categoryId)

  // Обновление самой категории
  const update = useUpdateCategoryApi()

  // Сохраняем все изменения
  const updateCategoryHandler = () => {
    attributes.map((attributeId) => {
      const attribute = category?.attributes.find(({ id }) => {
        return id === attributeId
      })

      handleUpdateAttribute(
        attributeId,
        watchedFields[attributeId],
        attribute?.value_id,
        attribute?.attribute_type,
      )
    })

    const categoryUpdateData: Record<string, CategoryFieldNames> = {}

    categoryFields.forEach((field) => {
      categoryUpdateData[field] =
        field === 'object_group'
          ? watchedFields.object_group.map((item: { value: number }) => {
              return item.value
            })
          : watchedFields[field]
    })

    update.mutate(
      {
        params: {
          path: {
            id: categoryId,
          },
        },
        body: categoryUpdateData,
      },
      {
        onSuccess,
      },
    )
  }

  return {
    updateCategoryHandler,
    isSubmitting: isSubmittingAttributes || update.isPending,
  }
}

export { useUpdateCategory }
