import { EuiForm, EuiHeaderLink } from '@elastic/eui'
import { ACTION, LABEL, MESSAGE } from '@shared/config'
import { FormError, TextInput } from '@shared/ui/form'
import { Modal } from '@shared/ui/modal'
import { FormProvider } from 'react-hook-form'
import { useParams } from 'react-router'

import { useAddAttributeOption } from '../model'

const AddAttributeOption: ReactFc = () => {
  const { id: attributeIdParam } = useParams()
  const attributeId = Number(attributeIdParam)

  const { isOpen, showModal, closeModal, methods, addOption, isSubmitting } =
    useAddAttributeOption(attributeId)

  return (
    <>
      <EuiHeaderLink iconType="plusInCircle" color="primary" iconSide="right" onClick={showModal}>
        {ACTION.ADD_OPTION}
      </EuiHeaderLink>

      <FormProvider {...methods}>
        <Modal
          title={ACTION.ADD_OPTION}
          onClose={closeModal}
          onSubmit={addOption}
          isOpen={isOpen}
          isSubmitting={isSubmitting}
        >
          <EuiForm component="form">
            <TextInput
              name="name"
              placeholder={LABEL.NAME}
              registerOptions={{ required: MESSAGE.ENTER_NAME }}
              inputProps={{
                disabled: isSubmitting,
              }}
              isRequired
            />

            <FormError />
          </EuiForm>
        </Modal>
      </FormProvider>
    </>
  )
}

export { AddAttributeOption }
