import { EuiHeaderLink } from '@elastic/eui'
import { ACTION } from '@shared/config'

import { useRemoveAttributeGroups } from '../model'

const RemoveAttributeGroups: ReactFc = () => {
  const { isDisabledButton, isSubmitting, removeFromGroups } = useRemoveAttributeGroups()

  return (
    <EuiHeaderLink
      iconType="trash"
      color="danger"
      iconSide="right"
      onClick={removeFromGroups}
      isLoading={isSubmitting}
      disabled={isDisabledButton}
    >
      {ACTION.DELETE_SELECTED}
    </EuiHeaderLink>
  )
}

export { RemoveAttributeGroups }
