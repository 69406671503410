import { $api } from '@shared/api'
import { LABEL } from '@shared/config'
import { isNotNull } from '@shared/lib'
import { ComboBox } from '@shared/ui/form'
import { useEffect, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'

import { type FilterFields } from '../../types'
import { comboBoxWidth } from '../style'
import { type FilterAttributeProps } from '../types'

const FilterEqual: ReactFc<FilterAttributeProps> = ({ attribute }) => {
  const { slug } = attribute
  const { getValues, setValue } = useFormContext<FilterFields>()
  const currentField = getValues(`filters.${slug}`)?.filterParams[0]

  const { isLoading, data: attributeValueList } = $api.useQuery('get', '/attribute_values_list/', {
    params: {
      query: {
        attribute: attribute?.id,
      },
    },
  })

  const attributeValueOptions = useMemo(() => {
    return attributeValueList?.results
      ?.map((attributeValue) => {
        if (attributeValue.id && attributeValue.value) {
          return {
            value: attributeValue.id,
            label: attributeValue.value,
          }
        }

        return null
      })
      .filter(isNotNull)
  }, [attributeValueList])

  // Логика восстановления состояния ComboBox
  useEffect(() => {
    if (currentField.value && attributeValueOptions) {
      // Ищем опцию, соответствующую текущему значению
      const selectedOption = attributeValueOptions.find((option) => {
        return option.label === currentField.value
      })

      if (selectedOption) {
        // Устанавливаем выбранное значение в ComboBox
        setValue(`filters.${slug}.filterComboBox`, [selectedOption])
      }
    }
  }, [attributeValueOptions])

  return (
    <ComboBox
      placeholder={LABEL.VALUE}
      name={`filters.${slug}.filterComboBox`}
      inputProps={{
        options: attributeValueOptions,
        onChange: (selectedOptions) => {
          const filterValues = selectedOptions.map((option) => {
            return option.label
          })

          setValue(`filters.${slug}.filterComboBox`, selectedOptions)

          setValue(`filters.${slug}.filterParams`, [
            {
              param: currentField.param,
              value: filterValues,
            },
          ])
        },
        fullWidth: true,
        compressed: true,
        isDisabled: isLoading,
        isLoading,
        css: comboBoxWidth,
      }}
    />
  )
}

export { FilterEqual }
