import { EuiButtonIcon } from '@elastic/eui'
import { useUserSettingsStore } from '@entities/user'

export const SkuSidebarToggle: ReactFc = () => {
  const isSkuSidebarOpen = useUserSettingsStore.useIsSkuSidebarOpen()
  const toggleSkuSidebarOpen = useUserSettingsStore.useToggleSkuSidebarOpen()

  if (isSkuSidebarOpen) {
    return (
      <EuiButtonIcon
        size="xs"
        color="text"
        display="base"
        iconType="arrowLeft"
        aria-label="Скрыть описание товара"
        onClick={toggleSkuSidebarOpen}
      />
    )
  }

  return (
    <EuiButtonIcon
      size="xs"
      color="primary"
      display="fill"
      iconType="arrowRight"
      aria-label="Показать описание товара"
      onClick={toggleSkuSidebarOpen}
    />
  )
}
