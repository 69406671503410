import { type EuiBasicTableColumn } from '@elastic/eui'
import { type ApiAttributeOption } from '@shared/api/types'
import { ATTRIBUTE_FIELD_NAMES, LABEL } from '@shared/config'
import { getDateColumnRender } from '@shared/lib'

const ATTRIBUTE_OPTIONS_COLUMNS: EuiBasicTableColumn<ApiAttributeOption>[] = [
  {
    field: 'value',
    name: LABEL.NAME,
  },
  {
    field: 'created_at',
    name: ATTRIBUTE_FIELD_NAMES.created_at,
    render: getDateColumnRender<ApiAttributeOption, 'created_at'>(),
  },
  {
    field: 'updated_at',
    name: ATTRIBUTE_FIELD_NAMES.updated_at,
    render: getDateColumnRender<ApiAttributeOption, 'updated_at'>(),
  },
]

export { ATTRIBUTE_OPTIONS_COLUMNS }
