import { EuiFlexGroup, EuiFlexItem, EuiIcon, EuiText } from '@elastic/eui'
import { css } from '@emotion/react'
import { ELEMENTS_XXS_WIDTH, IMPORT_STATUS, MESSAGE } from '@shared/config'
import { Progress } from '@shared/ui/progress'

import { type FilesCardDownloadInfoProps } from '../types'
import { LogButton } from './log-button'
import { type ImportStatus } from './types'

const getImportStatusInfo = (importStatus: ImportStatus) => {
  switch (importStatus) {
    case IMPORT_STATUS.IMPORTED:
      return {
        iconType: 'checkInCircleFilled',
        iconColor: 'success',
        text: MESSAGE.IMPORT,
      }

    case IMPORT_STATUS.IMPORT_ERROR:
      return {
        iconType: 'warning',
        iconColor: 'danger',
        text: MESSAGE.IMPORT,
      }

    default:
      return {
        iconType: '',
        iconColor: '',
        text: '',
      }
  }
}

const FilesCardDownloadInfo: ReactFc<FilesCardDownloadInfoProps> = ({
  importStatus,
  progress,
  log,
}) => {
  const renderValidationStatus = () => {
    const canShow = importStatus && importStatus !== IMPORT_STATUS.LOADED

    // Не показываем статус валидации, пока файл не загружен на сервер

    if (!canShow) {
      return null
    }

    // В остальных случаях статус валидации показываем

    const isError = importStatus === IMPORT_STATUS.VALIDATION_ERROR

    const iconType = isError ? 'warning' : 'checkInCircleFilled'
    const iconColor = isError ? 'danger' : 'success'

    return (
      <EuiFlexItem grow={false}>
        <EuiFlexGroup alignItems="center" gutterSize="s">
          <EuiIcon type={iconType} color={iconColor} size="m" />

          <EuiText size="s">Валидация</EuiText>
        </EuiFlexGroup>
      </EuiFlexItem>
    )
  }

  const renderImportStatus = () => {
    const canShow =
      importStatus &&
      importStatus > IMPORT_STATUS.VALIDATED &&
      importStatus !== IMPORT_STATUS.VALIDATION_ERROR

    // Не показываем статус импорта, если валидация не прошла или прошла с ошибкой

    if (!canShow) {
      return null
    }

    const card = css`
      min-width: ${ELEMENTS_XXS_WIDTH}px;
    `
    // Показываем прогресс импорта вместо статуса, если он менее 100% и нет ошибки импорта

    if (progress && progress < 100 && importStatus !== IMPORT_STATUS.IMPORT_ERROR) {
      return (
        <EuiFlexItem>
          <EuiFlexGroup alignItems="center" gutterSize="s">
            <Progress value={progress} css={card} />
          </EuiFlexGroup>
        </EuiFlexItem>
      )
    }

    const { iconType, iconColor, text } = getImportStatusInfo(importStatus)

    // Показываем статус импорта, если импорт завершен успешно или с ошибкой

    return (
      <EuiFlexItem grow={false}>
        <EuiFlexGroup alignItems="center" gutterSize="s">
          <EuiIcon type={iconType} color={iconColor} size="m" />

          <EuiText size="s">{text}</EuiText>
        </EuiFlexGroup>
      </EuiFlexItem>
    )
  }

  return (
    <EuiFlexGroup alignItems="center" justifyContent="flexEnd">
      {renderValidationStatus()}
      {renderImportStatus()}

      {/* Кнопка лога показывается, только если есть url лога */}
      <LogButton url={log} />
    </EuiFlexGroup>
  )
}

export { FilesCardDownloadInfo }
