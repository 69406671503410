import { EuiText } from '@elastic/eui'
import { ACTION, ELEMENTS_L_WIDTH, LABEL, MESSAGE } from '@shared/config'
import { Modal } from '@shared/ui/modal'
import { useEffect } from 'react'

import { useNotificationsStore } from '../store'
import { type LeaveNotificationModalProps } from './types'

const LeaveNotificationModal: ReactFc<LeaveNotificationModalProps> = ({
  isDirty,
  isSubmitting,
  onSave,
}) => {
  const onLeave = useNotificationsStore.useOnLeave()
  const setOnLeave = useNotificationsStore.useSetOnLeave()
  const setCanLeave = useNotificationsStore.useSetCanLeave()

  useEffect(() => {
    setCanLeave(!isDirty)

    // Текст алерта не получится заменить, по соображениям безопасности в браузере
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault()
    }

    if (!onLeave && isDirty) {
      window.addEventListener('beforeunload', handleBeforeUnload)
    }

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [isDirty, onLeave])

  //TODO: isDirty после сохранения изменений, все равно остается true, это создает баг
  useEffect(() => {
    return () => {
      setCanLeave(true)
    }
  }, [])

  const resetStorValues = () => {
    setOnLeave(null)
    setCanLeave(true)
  }

  const hadleLeave = () => {
    if (onLeave) {
      onLeave()
    }

    resetStorValues()
  }

  const handleSubmit = async () => {
    await onSave()
    hadleLeave()
  }

  return (
    <Modal
      title={LABEL.NOTIFICATION}
      onClose={() => {
        setOnLeave(null)
      }}
      onSubmit={handleSubmit}
      onCancel={hadleLeave}
      isOpen={!!onLeave}
      isSubmitting={isSubmitting}
      submitButtonText={ACTION.SAVE_AND_LEAVE}
      cancelButtonText={ACTION.LEAVE_WITHOUT_SAVE}
      maxWidth={ELEMENTS_L_WIDTH}
    >
      <EuiText>{MESSAGE.UNSAVED_CHANGES}</EuiText>
    </Modal>
  )
}

export { LeaveNotificationModal }
