import { FILE_UPLOAD_ALLOWED } from '@shared/config'
import { FilePicker } from '@shared/ui/form'
import { useNotificationsStore } from '@shared/ui/notifications'

import { useUploadAttributeValue } from '../model'
import { type AttributeProps } from './types'

const AttributeFile: ReactFc<AttributeProps> = (props) => {
  const { attribute, isCompressed = false, isSubmitting, placeholder, inputProps } = props

  // Функция для загрузки файла в attribute_value
  const { filePickerRef, uploadFileToAttributeValue, isFileSubmitting } = useUploadAttributeValue()

  const canLeave = useNotificationsStore.useCanLeave()
  const setOnLeave = useNotificationsStore.useSetOnLeave()

  const buildChangeHandler = () => {
    return (files: FileList | null) => {
      if (canLeave) {
        return uploadFileToAttributeValue({
          attribute,
          files,
        })
      }

      return setOnLeave(() => {
        uploadFileToAttributeValue({
          attribute,
          files,
        })
      })
    }
  }

  return (
    <FilePicker
      name={attribute.id.toString()}
      placeholder={placeholder}
      inputProps={{
        ref: filePickerRef,
        accept: attribute.is_preview ? FILE_UPLOAD_ALLOWED.IMAGE : FILE_UPLOAD_ALLOWED.DOCUMENTS,
        display: isCompressed ? 'default' : 'large',
        isLoading: isFileSubmitting,
        disabled: isSubmitting || isFileSubmitting || inputProps.disabled,
        onChange: buildChangeHandler(),
      }}
    />
  )
}

export { AttributeFile }
