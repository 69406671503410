import { useAttributeGroupsList } from '@entities/attributes'
import { $api, API_OBJECT_GROUP, API_QUERY_KEY, queryClient } from '@shared/api'
import { ERROR, NOTIFICATIONS } from '@shared/config'
import { useDialog } from '@shared/hooks'
import { useNotificationsStore } from '@shared/ui/notifications'
import { useForm } from 'react-hook-form'

import { type AddAttributeGroupFormInputs } from './types'

export const useAddAttributeGroup = () => {
  const { isOpen, handleOpenDialog: showModal, handleCloseDialog } = useDialog()
  const addNotification = useNotificationsStore.useAddNotification()
  const methods = useForm<AddAttributeGroupFormInputs>()

  const attributesGroups = useAttributeGroupsList()

  const newOrder = attributesGroups.data && attributesGroups.data.length + 1

  if (!methods.formState.dirtyFields.order && newOrder) {
    methods.setValue('order', newOrder)
  }

  const closeModal = () => {
    handleCloseDialog()
    methods.reset()
  }

  const addAttributeGroup = $api.useMutation('post', '/object_group_value/', {
    onError: () => {
      methods.setError('root', {
        type: 'manual',
        message: ERROR.SERVER,
      })
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: API_QUERY_KEY.ATTRIBUTE_GROUP_LIST })
      closeModal()
      addNotification(NOTIFICATIONS.ATTRIBUTE_GROUP_CREATE_SUCCESS)
    },
  })

  const addGroup = methods.handleSubmit(({ name, order }) => {
    addAttributeGroup.mutate({
      body: {
        type: API_OBJECT_GROUP.ATTRIBUTE,
        value: name,
        order: order || null,
      },
    })
  })

  return {
    isOpen,
    showModal,
    closeModal,
    methods,
    addGroup,
    isSubmitting: addAttributeGroup.isPending,
  }
}
