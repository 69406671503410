import { EuiContextMenuItem } from '@elastic/eui'

import { type MenuItemDialogProps } from './types'

const MenuItemDialog: ReactFc<MenuItemDialogProps> = ({
  onClick,
  closeContextMenu,
  children,
  ...props
}) => {
  const handleClick = (event: React.MouseEvent<Element>) => {
    onClick?.(event)

    closeContextMenu?.()
  }

  return (
    <EuiContextMenuItem icon="pencil" size="s" onClick={handleClick} {...props}>
      {children}
    </EuiContextMenuItem>
  )
}

export { MenuItemDialog }
