import { useAddSkuApi } from '@entities/catalog'
import { API_QUERY_KEY, queryClient } from '@shared/api'
import { NOTIFICATIONS } from '@shared/config'
import { useNotificationsStore } from '@shared/ui/notifications'
import { useForm } from 'react-hook-form'

import { type AddSkuFormInputs } from './types'

const useAddSku = (onClose: EmptyCallback) => {
  const addSku = useAddSkuApi()
  const addNotification = useNotificationsStore.useAddNotification()
  const methods = useForm<AddSkuFormInputs>()

  const handleModalClose = () => {
    methods.reset()
    onClose()
  }

  const onSuccess = () => {
    queryClient.invalidateQueries({ queryKey: API_QUERY_KEY.SKU_LIST })
    handleModalClose()
    addNotification(NOTIFICATIONS.SKU_CREATE_SUCCESS)
  }

  const onError = (error: never) => {
    methods.setError('value_sku', {
      type: 'manual',
      message: error[0],
    })
  }

  const handleSubmit = methods.handleSubmit((fieldsData) => {
    addSku.mutate(
      {
        body: fieldsData,
      },
      { onSuccess, onError },
    )
  })

  return {
    handleModalClose,
    methods,
    handleSubmit,
    isSubmitting: addSku.isPending,
  }
}

export { useAddSku }
