import { useAddAutomaticTaskApi } from '@entities/automatic_tasks'
import { API_QUERY_KEY, queryClient } from '@shared/api'
import { type ApiAutomaticTask } from '@shared/api/types'
import { NOTIFICATIONS } from '@shared/config'
import { useNotificationsStore } from '@shared/ui/notifications'
import { useForm } from 'react-hook-form'

import { type AddAutomaticTaskFormInputs, type UseAddAutomaticTaskArgs } from './types'

const useAddAutomaticTask = ({ onClose }: UseAddAutomaticTaskArgs) => {
  const addNotification = useNotificationsStore.useAddNotification()

  const { mutate: addAutomaticTask, isPending: isAutomaticTaskSubmitting } =
    useAddAutomaticTaskApi()

  const methods = useForm<AddAutomaticTaskFormInputs>()

  const onSuccess = () => {
    queryClient.refetchQueries({ queryKey: API_QUERY_KEY.AUTOMATIC_TASKS })
    methods.reset()
    onClose()
    addNotification(NOTIFICATIONS.TASK_CREATE_SUCCESS)
  }

  const onError = (error: Record<keyof AddAutomaticTaskFormInputs, string | string[]>) => {
    Object.keys(error).forEach((key) => {
      const typedKey = key as keyof AddAutomaticTaskFormInputs

      const message = error[typedKey]

      if (Array.isArray(message)) {
        message.forEach((text) => {
          methods.setError(typedKey, {
            type: 'manual',
            message: text,
          })
        })

        return
      }

      methods.setError(typedKey, {
        type: 'manual',
        message,
      })
    })
  }

  const handleAddAutomaticTask = methods.handleSubmit((userData: Partial<ApiAutomaticTask>) => {
    const { crontab_schedule } = userData

    const isCrontabEmpty =
      Object.values(crontab_schedule || {})
        .join('')
        .trim() === ''

    const body = {
      ...userData,
      crontab_schedule: isCrontabEmpty ? undefined : crontab_schedule,
    }

    return addAutomaticTask({ body: body as ApiAutomaticTask }, { onSuccess, onError })
  })

  const handleModalClose = () => {
    methods.reset()
    onClose()
  }

  return {
    methods,
    handleAddAutomaticTask,
    handleModalClose,
    isSubmitting: isAutomaticTaskSubmitting,
  }
}

export { useAddAutomaticTask }
