import { EuiForm } from '@elastic/eui'
import { ACTION, LABEL, MESSAGE } from '@shared/config'
import { FormError, NumberInput, TextInput } from '@shared/ui/form'
import { Modal } from '@shared/ui/modal'
import { FormProvider } from 'react-hook-form'

import { useUpdateAttributeGroup } from '../model'
import { type UpdateAttributeGroupModalProps } from './types'

const UpdateAttributeGroupModal: ReactFc<UpdateAttributeGroupModalProps> = ({
  id,
  isOpen,
  closeModal,
}) => {
  const { methods, updateGroup, isSubmitting } = useUpdateAttributeGroup(id, closeModal)

  return (
    <FormProvider {...methods}>
      <Modal
        title={ACTION.EDIT_ATTRIBUTE_GROUP}
        submitButtonText={ACTION.SAVE}
        onClose={closeModal}
        onSubmit={updateGroup}
        isOpen={isOpen}
        isSubmitting={isSubmitting}
      >
        <EuiForm component="form">
          <TextInput
            name="name"
            placeholder={LABEL.ATTRIBUTE_GROUP_NAME}
            registerOptions={{ required: MESSAGE.ENTER_NAME }}
            inputProps={{
              disabled: isSubmitting,
            }}
            isRequired
          />
          <NumberInput
            name="order"
            placeholder={LABEL.ORDER}
            inputProps={{
              disabled: isSubmitting,
            }}
          />

          <FormError />
        </EuiForm>
      </Modal>
    </FormProvider>
  )
}

export { UpdateAttributeGroupModal }
