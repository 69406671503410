import { type components } from '@shared/api/openapi-types'
import { type FieldError, type FieldValues, type UseFormGetValues } from 'react-hook-form'

export type CronInputProps = {
  name: string
  label?: string
}

export type CronTemplatesProps = {
  onChange: (event: Required<CrontabSchedule>) => void
}

export type CrontabSchedule = components['schemas']['CrontabSchedule']

export type ValidateArgs = {
  name: keyof CrontabSchedule
  value: string
}

export enum CRON_FIELD_NAME {
  MINUTE = 'minute',
  HOUR = 'hour',
  DAY_OF_MONTH = 'day_of_month',
  MONTH_OF_YEAR = 'month_of_year',
  DAY_OF_WEEK = 'day_of_week',
}

export type HandleValidationArgs = {
  name: keyof CrontabSchedule
  value: string
}

export type GetCronFieldsArgs = {
  crontabName: string
  getValues: UseFormGetValues<FieldValues>
  errors: Record<string, FieldError>
  isDisabled?: boolean
  onChange: (args: React.ChangeEvent<HTMLInputElement>) => void
}
