import { useUpdateAttribute } from '@entities/attributes'
import { type ApiAttribute } from '@shared/api/types'
import { useRouterParams } from '@shared/hooks'
import { useForm } from 'react-hook-form'

import { type LinkAttributeToCategoryOnSubmitArg } from '../types'

const useLinkAttributeToCategory = () => {
  const methods = useForm<ApiAttribute>()
  const { getParamValues } = useRouterParams()
  const attributeIds = getParamValues('selected')
  const updateAttributeMutation = useUpdateAttribute()

  const updateAttributes = (data: LinkAttributeToCategoryOnSubmitArg) => {
    return Promise.all(
      attributeIds.map((idParam) => {
        const attributeId = Number(idParam)

        return updateAttributeMutation.mutateAsync({
          params: {
            path: {
              id: attributeId,
            },
          },
          body: data.category ? data : { category: null },
        })
      }),
    )
  }

  return {
    methods,
    updateAttributes,
    isSubmitting: updateAttributeMutation.isPending,
  }
}

export { useLinkAttributeToCategory }
