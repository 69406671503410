import { EuiContextMenuItem, EuiIcon, useEuiTheme } from '@elastic/eui'
import { useRemoveCategory } from '@entities/categories'
import { API_QUERY_KEY, queryClient } from '@shared/api'
import { ACTION, ERROR } from '@shared/config'
import { InfoTooltip } from '@shared/ui'

const MenuItemRemoveCategory: ReactFc<ItemWithId> = ({ id }) => {
  const onSuccess = () => {
    queryClient.invalidateQueries({ queryKey: API_QUERY_KEY.CATEGORY_LIST })
  }

  const { isDisabledButton, isLoading, isSubmitting, removeCategory } = useRemoveCategory(
    id,
    onSuccess,
  )

  const { euiTheme } = useEuiTheme()

  return (
    <InfoTooltip
      tooltip={ERROR.NOT_EMPTY}
      color={euiTheme.colors.danger}
      isShow={isDisabledButton && !isLoading}
    >
      <EuiContextMenuItem
        icon={<EuiIcon type="trash" color="danger" />}
        size="s"
        disabled={isDisabledButton || isSubmitting}
        onClick={removeCategory}
      >
        {ACTION.DELETE}
      </EuiContextMenuItem>
    </InfoTooltip>
  )
}

export { MenuItemRemoveCategory }
