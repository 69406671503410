import {
  EuiFlexGroup,
  EuiHeader,
  EuiHeaderLinks,
  EuiHeaderSection,
  EuiHeaderSectionItem,
} from '@elastic/eui'
import { noShadow } from '@shared/ui/styles'

import { Breadcrumbs } from './breadcrumbs'
import { UserHeaderMenu } from './user-header-menu'

export const Header = () => {
  return (
    <EuiHeader css={noShadow}>
      <EuiHeaderSection>
        <EuiFlexGroup alignItems="center" gutterSize="s">
          <Breadcrumbs />
        </EuiFlexGroup>
      </EuiHeaderSection>
      <EuiHeaderSection>
        <EuiHeaderSectionItem>
          <EuiHeaderLinks>
            <UserHeaderMenu />
          </EuiHeaderLinks>
        </EuiHeaderSectionItem>
      </EuiHeaderSection>
    </EuiHeader>
  )
}
