import { createSelectorHooks, type ZustandHookSelectors } from 'auto-zustand-selectors-hook'
import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'

import { type PagiantionStore, type PaginationInitialValues } from './types'

const initialValues: PaginationInitialValues = {
  pageSizeList: {},
}

const paginationStore = create<PagiantionStore>()(
  persist(
    (set, get) => {
      return {
        ...initialValues,

        setPageSize: (pageSize) => {
          return set({ pageSizeList: { ...get().pageSizeList, ...pageSize } })
        },
      }
    },
    {
      name: 'pagination',
      storage: createJSONStorage(() => {
        return localStorage
      }),
    },
  ),
)

const usePaginationStore = createSelectorHooks(paginationStore) as typeof paginationStore &
  ZustandHookSelectors<PagiantionStore>

export { paginationStore, usePaginationStore }
