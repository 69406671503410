import { css } from '@emotion/react'
import { type GetStyles } from '@shared/ui/styles'

export const getStyles: GetStyles = () => {
  return {
    checkboxInputBlockSize: css`
      .euiFormRow__fieldWrapper {
        min-block-size: unset;
      }
    `,
  }
}
