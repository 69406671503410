import { $api } from '@shared/api'

const useAttributeApi = (id: number) => {
  return $api.useQuery('get', '/attributes/{id}/', {
    params: {
      path: {
        id,
      },
    },
  })
}

export { useAttributeApi }
