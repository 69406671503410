import { PARAMS } from '@shared/config'

import { DateRangeFilter } from '../date-range-filter'
import { type FilterAttributeProps } from '../types'

const FilterDateRange: ReactFc<FilterAttributeProps> = ({ attribute }) => {
  const { slug } = attribute

  return (
    <DateRangeFilter
      paramPrefix={slug}
      paramSuffixes={{
        after: PARAMS.ATTRIBUTE_AFTER,
        before: PARAMS.ATTRIBUTE_BEFORE,
      }}
    />
  )
}

export { FilterDateRange }
