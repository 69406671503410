import { attributeTypeOptions, useAttributeApi, useAttributeGroupsList } from '@entities/attributes'
import { type AttributeGroup } from '@entities/attributes/types'
import { useCategoryOptions } from '@entities/categories'
import { ATTRIBUTE_FIELD_NAMES, ATTRIBUTE_FIELDS, MESSAGE } from '@shared/config'
import { getLocalDate, getSelectInputOptions } from '@shared/lib'
import { InfoPageRow } from '@shared/ui'
import { CheckboxInput, SelectInput, TextAreaInput, TextInput } from '@shared/ui/form'

import { type AttributeInfoRowsProps } from './types'

const AttributeInfoRows: ReactFc<AttributeInfoRowsProps> = ({ id: attributeId, isSubmitting }) => {
  const { data: attribute, isLoading: isAttributeLoading } = useAttributeApi(attributeId)
  const isDisabled = isAttributeLoading || isSubmitting

  const { categoryOptions, isLoading: isCategoryOptionsLoading } = useCategoryOptions()

  const { data: groupData, isLoading: isGroupOptionsLoading } = useAttributeGroupsList()

  const groupOptions = getSelectInputOptions<AttributeGroup>({
    items: groupData,
    valueKey: 'id',
    inputDisplayKey: 'name',
  })

  const rowsConfig = [
    {
      name: ATTRIBUTE_FIELDS.UUID,
      field: <>{attribute?.uuid}</>,
    },
    {
      name: ATTRIBUTE_FIELDS.IS_ENABLED,
      field: (
        <CheckboxInput name={ATTRIBUTE_FIELDS.IS_ENABLED} inputProps={{ disabled: isDisabled }} />
      ),
    },
    {
      name: ATTRIBUTE_FIELDS.IS_DELETED,
      field: (
        <CheckboxInput name={ATTRIBUTE_FIELDS.IS_DELETED} inputProps={{ disabled: isDisabled }} />
      ),
    },
    {
      name: ATTRIBUTE_FIELDS.NAME,
      field: (
        <TextInput
          name={ATTRIBUTE_FIELDS.NAME}
          inputProps={{ disabled: isDisabled, compressed: true }}
        />
      ),
    },
    {
      name: ATTRIBUTE_FIELDS.ATTRIBUTE_TYPE,
      field: (
        <SelectInput
          name={ATTRIBUTE_FIELDS.ATTRIBUTE_TYPE}
          inputProps={{
            options: attributeTypeOptions,
            disabled: isDisabled,
            compressed: true,
          }}
        />
      ),
    },
    {
      name: ATTRIBUTE_FIELDS.CATEGORY,
      field: (
        <SelectInput
          name={ATTRIBUTE_FIELDS.CATEGORY}
          inputProps={{
            options: categoryOptions,
            isLoading: isCategoryOptionsLoading,
            disabled: isDisabled,
            compressed: true,
          }}
        />
      ),
    },
    {
      name: ATTRIBUTE_FIELDS.IS_FEATURED,
      tooltip: MESSAGE.IS_FEATURED,
      field: (
        <CheckboxInput name={ATTRIBUTE_FIELDS.IS_FEATURED} inputProps={{ disabled: isDisabled }} />
      ),
    },
    {
      name: ATTRIBUTE_FIELDS.IS_REQUIRED,
      field: (
        <CheckboxInput name={ATTRIBUTE_FIELDS.IS_REQUIRED} inputProps={{ disabled: isDisabled }} />
      ),
    },
    {
      name: ATTRIBUTE_FIELDS.DESCRIPTION,
      field: (
        <TextAreaInput name={ATTRIBUTE_FIELDS.DESCRIPTION} inputProps={{ disabled: isDisabled }} />
      ),
    },
    {
      name: ATTRIBUTE_FIELDS.IS_PUBLIC,
      field: (
        <CheckboxInput name={ATTRIBUTE_FIELDS.IS_PUBLIC} inputProps={{ disabled: isDisabled }} />
      ),
    },
    {
      name: ATTRIBUTE_FIELDS.OBJECT_GROUP,
      field: (
        <SelectInput
          name={ATTRIBUTE_FIELDS.OBJECT_GROUP}
          inputProps={{
            options: groupOptions,
            isLoading: isGroupOptionsLoading,
            disabled: isDisabled,
            compressed: true,
          }}
        />
      ),
    },
    {
      name: ATTRIBUTE_FIELDS.IS_BRAND,
      field: (
        <CheckboxInput name={ATTRIBUTE_FIELDS.IS_BRAND} inputProps={{ disabled: isDisabled }} />
      ),
    },
    {
      name: ATTRIBUTE_FIELDS.IS_PREVIEW,
      field: (
        <CheckboxInput name={ATTRIBUTE_FIELDS.IS_PREVIEW} inputProps={{ disabled: isDisabled }} />
      ),
    },
    {
      name: ATTRIBUTE_FIELDS.SLUG,
      field: (
        <TextInput name={ATTRIBUTE_FIELDS.SLUG} inputProps={{ disabled: true, compressed: true }} />
      ),
    },
    {
      name: ATTRIBUTE_FIELDS.CREATED_BY,
      field: <>{attribute?.created_by || MESSAGE.EMPTY_VALUE}</>,
    },
    {
      name: ATTRIBUTE_FIELDS.CREATED_AT,
      field: <>{getLocalDate(attribute?.created_at) || MESSAGE.EMPTY_VALUE}</>,
    },
    {
      name: ATTRIBUTE_FIELDS.UPDATED_AT,
      field: <>{getLocalDate(attribute?.updated_at) || MESSAGE.EMPTY_VALUE}</>,
    },
  ]

  const rows = rowsConfig.map(({ name, field, tooltip }) => {
    return (
      <InfoPageRow
        key={name}
        label={ATTRIBUTE_FIELD_NAMES[name] || ''}
        field={field}
        tooltip={tooltip}
      />
    )
  })

  return rows
}

export { AttributeInfoRows }
