import { $api } from '@shared/api'

import { type AutomaticTaskListQuery } from './types'

const useAutomaticTaskListApi = (args: AutomaticTaskListQuery = {}) => {
  return $api.useQuery('get', '/automatic_task/', {
    params: {
      query: {
        ...args,
      },
    },
  })
}

export { useAutomaticTaskListApi }
