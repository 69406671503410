import { type FieldError, type FieldErrors } from 'react-hook-form'

const isFieldError = (error: FieldError | FieldErrors | undefined): error is FieldError => {
  return (
    error !== undefined &&
    'message' in error &&
    (typeof error.message === 'string' || Array.isArray(error.message))
  )
}

export { isFieldError }
