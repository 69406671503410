import { EuiForm } from '@elastic/eui'
import { useCategoryOptions } from '@entities/categories'
import { ACTION, MESSAGE, NOTIFICATIONS } from '@shared/config'
import { FormError, SelectInput } from '@shared/ui/form'
import { Modal } from '@shared/ui/modal'
import { useNotificationsStore } from '@shared/ui/notifications'
import { Controller, FormProvider } from 'react-hook-form'

import { useLinkAttributeToCategory } from '../model'
import {
  type LinkAttributeToCategoryOnSubmitArg,
  type LinkAttributeToCategoryProps,
} from '../types'

const LinkAttributeToCategory: ReactFc<LinkAttributeToCategoryProps> = ({ isOpen, onClose }) => {
  const { categoryOptions, isLoading: isCategoryOptionsLoading } = useCategoryOptions()
  const { methods, updateAttributes, isSubmitting } = useLinkAttributeToCategory()
  const addNotification = useNotificationsStore.useAddNotification()

  const onSubmit = async (data: LinkAttributeToCategoryOnSubmitArg) => {
    await updateAttributes(data)
    methods.reset()
    onClose()
    addNotification(NOTIFICATIONS.ATTRIBUTE_UPDATE_SUCCESS)
  }

  return (
    <FormProvider {...methods}>
      <Modal
        title={ACTION.NEW_ATTRIBUTE}
        submitButtonText={ACTION.ATTRIBUTES_LINK}
        onClose={onClose}
        onSubmit={methods.handleSubmit(onSubmit)}
        isOpen={isOpen}
        isSubmitting={isSubmitting}
      >
        <EuiForm component="form">
          <Controller
            name="category"
            control={methods.control}
            rules={{ required: MESSAGE.CATEGORY_REQUIRED }}
            render={() => {
              return (
                <SelectInput
                  name="category"
                  placeholder={ACTION.ATTRIBUTES_CATEGORY_SELECT}
                  label={ACTION.ATTRIBUTES_CATEGORY_SELECT}
                  inputProps={{
                    options: categoryOptions,
                    isLoading: isCategoryOptionsLoading,
                  }}
                />
              )
            }}
          />
          <FormError />
        </EuiForm>
      </Modal>
    </FormProvider>
  )
}

export { LinkAttributeToCategory }
