import { EuiGlobalToastList } from '@elastic/eui'

import { useNotificationsStore } from '../store'

const Notifications: ReactFc = () => {
  const notifications = useNotificationsStore.useNotifications()
  const removeNotification = useNotificationsStore.useRemoveNotification()

  return (
    <EuiGlobalToastList
      toasts={notifications}
      dismissToast={removeNotification}
      toastLifeTimeMs={5000}
    />
  )
}

export { Notifications }
