import { EuiFilterGroup } from '@elastic/eui'
import { LABEL } from '@shared/config'
import { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'

import { PARAM_DATE_OPTIONS, useCatalogFiltersList } from '../../model'
import { type FilterFields } from '../../types'
import { RemoveFilter } from '../remove-filter'
import { type SkuParam } from '../types'
import { FilterDate } from './filter-date'
import { FilterProgress } from './filter-progress'
import { FilterPublic } from './filter-public'

const FilterParam: ReactFc<SkuParam> = ({ skuParam }) => {
  const options = useCatalogFiltersList()
  const { getValues, setValue, resetField } = useFormContext<FilterFields>()

  const param = options?.find((option) => {
    return option.data.id === skuParam
  })

  const isProgress = param?.label === LABEL.PROGRESS
  const isPublic = param?.label === LABEL.PUBLIC
  const isDate = param?.label === LABEL.CREATE_DATE || param?.label === LABEL.UPDATE_DATE
  const isValueEmpty = !getValues(`filters.${skuParam}`)

  // Когда фильтр появляется на экране задаём дефолтные значения: атрибут заполнен у sku
  useEffect(() => {
    if (isValueEmpty) {
      if (isDate) {
        setValue(`filters.${skuParam}.filterParams`, PARAM_DATE_OPTIONS(skuParam)[0].data)
      }
    }

    return () => {
      if (isValueEmpty) {
        resetField(`filters.${skuParam}`)
      }
    }
  }, [])

  return (
    <EuiFilterGroup compressed>
      {/* Фильтрация по прогрессу */}
      {isProgress && <FilterProgress skuParam={skuParam} isValueEmpty={isValueEmpty} />}

      {/* Фильтрация по статусу публикации */}
      {isPublic && <FilterPublic skuParam={skuParam} isValueEmpty={isValueEmpty} />}

      {/* Фильтрация по дате создания и обновления */}
      {isDate && <FilterDate skuParam={skuParam} />}

      <RemoveFilter id={skuParam} />
    </EuiFilterGroup>
  )
}

export { FilterParam }
