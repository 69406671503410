import { EuiForm } from '@elastic/eui'
import {
  type EmailList,
  getAutomaticTaskTypeOptions,
  useEmailListsApi,
} from '@entities/automatic_tasks'
import { ACTION, DATE_FORMAT_FULL, LABEL, MESSAGE } from '@shared/config'
import { getSelectInputOptions } from '@shared/lib'
import {
  CheckboxInput,
  CronInput,
  DatePicker,
  FormError,
  SelectInput,
  TextInput,
} from '@shared/ui/form'
import { Modal } from '@shared/ui/modal'
import moment from 'moment'
import { FormProvider } from 'react-hook-form'

import { useAddAutomaticTask } from '../model'
import { type AddTaskModalProps } from './types'

const AddTaskModal: ReactFc<AddTaskModalProps> = ({ isOpen, onClose }) => {
  const { data: emailData, isLoading: isEmailListsLoading } = useEmailListsApi()

  const { handleAddAutomaticTask, handleModalClose, methods, isSubmitting } = useAddAutomaticTask({
    onClose,
  })

  const taskTypeOptions = getAutomaticTaskTypeOptions()

  const emailListOptions = getSelectInputOptions<EmailList>({
    items: emailData,
    valueKey: 'id',
    inputDisplayKey: 'name',
  })

  return (
    <FormProvider {...methods}>
      <Modal
        title={ACTION.NEW_TASK}
        onClose={handleModalClose}
        onSubmit={handleAddAutomaticTask}
        isOpen={isOpen}
        isSubmitting={isSubmitting}
      >
        <EuiForm component="form">
          <SelectInput
            name="task_type"
            placeholder={ACTION.TYPE_SELECT}
            label={LABEL.TYPE}
            registerOptions={{ required: MESSAGE.ENTER_TYPE }}
            inputProps={{
              options: taskTypeOptions,
              disabled: isSubmitting,
              compressed: true,
            }}
          />

          <TextInput
            name="name"
            placeholder={LABEL.NAME}
            registerOptions={{ required: MESSAGE.ENTER_NAME }}
            inputProps={{
              disabled: isSubmitting,
              compressed: true,
            }}
          />

          <SelectInput
            name="email_list"
            placeholder={ACTION.SELECT_LIST}
            label={LABEL.EMAIL_LIST}
            inputProps={{
              options: emailListOptions,
              isLoading: isEmailListsLoading,
              disabled: isSubmitting,
              compressed: true,
            }}
          />

          <CheckboxInput
            name="is_send_notifications"
            inputProps={{ label: LABEL.SEND_NOTIFICATION }}
            shouldOffBlockSize
          />

          {/* TODO: добавить актуальные данные после доработок с бэка */}
          <SelectInput
            name="trigger"
            placeholder={ACTION.SELECT_TRIGGER}
            label={LABEL.TRIGGER}
            inputProps={{
              options: emailListOptions,
              isLoading: isEmailListsLoading,
              disabled: true,
              compressed: true,
            }}
          />

          <DatePicker
            name="start_at"
            label={LABEL.START_DATE}
            inputProps={{
              compressed: true,
              placeholder: ACTION.SELECT_DATE,
              minDate: moment(),
              showTimeSelect: true,
              dateFormat: DATE_FORMAT_FULL,
            }}
          />

          <CronInput name="crontab_schedule" label={LABEL.REPEATABILITY} />

          <FormError />
        </EuiForm>
      </Modal>
    </FormProvider>
  )
}

export { AddTaskModal }
