import { API_ATTRIBUTE_TYPE } from '@shared/api'

import { type AttributeTypeHelp } from './types'

export const ATTRIBUTE_TYPE_HELP: AttributeTypeHelp = {
  [API_ATTRIBUTE_TYPE.TEXT]: {
    DESCRIPTION: 'Атрибут для ввода текста с ограничением в 255 символов',
    TOOLTIP: 'Введите текстовое значение. Допустимая длина текста: до 255 символов',
  },
  [API_ATTRIBUTE_TYPE.LIST]: {
    DESCRIPTION: 'Атрибут для выбора одного значения из доступного списка',
    TOOLTIP: 'Выберите значение из предложенных вариантов',
  },
  [API_ATTRIBUTE_TYPE.BOOL]: {
    DESCRIPTION: 'Атрибут для выбора логического значения: true (Да) или false (Нет)',
    TOOLTIP: 'Укажите логическое значение (true или false)',
  },
  [API_ATTRIBUTE_TYPE.NUMBER]: {
    DESCRIPTION: 'Атрибут для ввода значения типа integer (целое число)',
    TOOLTIP: 'Введите целое число. Допустимы только целые числа',
  },
  [API_ATTRIBUTE_TYPE.FILE]: {
    DESCRIPTION: 'Атрибут для хранения ссылки на файл',
    TOOLTIP: 'Выберите и загрузите файл',
  },
  [API_ATTRIBUTE_TYPE.JSON]: {
    DESCRIPTION: 'Атрибут для ввода данных в формате JSON. Данные должны быть валидными',
    TOOLTIP: 'Введите данные в формате JSON. JSON должен быть валидным',
  },
  [API_ATTRIBUTE_TYPE.DATE]: {
    DESCRIPTION: 'Атрибут для ввода значения, соответствующего формату datetime',
    TOOLTIP: 'Укажите дату и время. Формат ввода: ГГГГ-ММ-ДД ЧЧ:ММ:СС',
  },
  [API_ATTRIBUTE_TYPE.FLOAT]: {
    DESCRIPTION: 'Атрибут для ввода значений типа float (числа с плавающей точкой)',
    TOOLTIP: 'Введите число с плавающей точкой. Допустимы только числа с плавающей точкой',
  },
  [API_ATTRIBUTE_TYPE.LONG_TEXT]: {
    DESCRIPTION: 'Атрибут для ввода текста произвольной длины',
    TOOLTIP: 'Введите текст без ограничений длины',
  },
  // TODO: Добавить, когда появится на сервере
  [API_ATTRIBUTE_TYPE.NESTED_LIST]: {
    DESCRIPTION: '',
    TOOLTIP: '',
  },
}
