import { PARAMS, SKU_FILTERS_PARAMS } from '@shared/config'
import { useRouterParams } from '@shared/hooks'
import { useEffect, useMemo } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { useSearchParams } from 'react-router'

import { type FilterFields } from '../types'
import { useDefaultValues } from './use-default-values'

const useCatalogFilters = () => {
  const [searchParams] = useSearchParams()
  const { getParamValues, setParam, deleteParam, removeParamValue, params } = useRouterParams()

  // Активные фильтры
  const activeFilters = useMemo(() => {
    return getParamValues(PARAMS.FILTERS)
  }, [searchParams])

  // Создание defaultValues
  const defaultValues = useDefaultValues(activeFilters)
  const methods = useForm<FilterFields>({ defaultValues })

  // Слежение за изменениями в форме
  const watchedFilters = useWatch({ control: methods.control })
  const filters = watchedFilters[PARAMS.FILTERS]

  // Функция для проверки наличия параметра в фильтрах
  const isParamInFilters = useMemo(() => {
    return (key: string) => {
      if (!Array.isArray(filters)) return false

      return filters.some((filter) => {
        return filter.filterParams.some(({ param }: { param: string }) => {
          return param === key
        })
      })
    }
  }, [filters])

  // Устанавливаем параметр, которые активны в форме
  useEffect(() => {
    // Удаляем параметры, которых нет в фильтрах
    Object.keys(params).forEach((key) => {
      if (
        SKU_FILTERS_PARAMS.some((param) => {
          return key.includes(param)
        }) &&
        !isParamInFilters(key)
      ) {
        deleteParam(key)
      }
    })

    // Включаем активные фильтры
    for (const filter in filters) {
      const { filterParams } = filters[filter]!

      if (activeFilters.includes(filter) && filterParams) {
        filterParams.forEach(({ param, value }) => {
          if (param && value) {
            setParam(param, value)
          }
        })
      }
    }
  }, [filters, activeFilters])

  // Удаляем фильтр
  const removeFilter = (id: string) => {
    return () => {
      removeParamValue(PARAMS.FILTERS, id)
      methods.resetField(`filters.${id}`)
    }
  }

  return {
    methods,
    activeFilters,
    removeFilter,
  }
}

export { useCatalogFilters }
