import { type Criteria, type EuiTableSortingType } from '@elastic/eui'
import { PARAMS, SKU } from '@shared/config'
import { useRouterParams } from '@shared/hooks'
import { useEffect, useState } from 'react'

const useSkuTableSort = <T extends Obj>(defaultSort = '') => {
  const { params, setParam, deleteParam } = useRouterParams()
  const [sortField, setSortField] = useState<keyof T | string>(defaultSort)
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc')

  // Восстанавливаем сортировку из урла
  useEffect(() => {
    for (const param in params) {
      if (param === PARAMS.ORDERING || param === PARAMS.ORDER_BY) {
        const sortField = params[param]
        setSortField(sortField.replace(/^-/, '') as keyof T)
        setSortDirection(sortField.startsWith('-') ? 'desc' : 'asc')
      }
    }
  }, [])

  const sorting: EuiTableSortingType<T> = {
    sort: {
      field: sortField as keyof T,
      direction: sortDirection,
    },
    enableAllColumns: true,
  }

  const handleTableChange = ({ sort }: Criteria<T>) => {
    if (sort) {
      const { field: sortField, direction: sortDirection } = sort
      const direction = sortDirection === 'asc' ? '' : '-'

      if (sortField === SKU.PROGRESS) {
        deleteParam(PARAMS.ORDER_BY)
        setParam(PARAMS.ORDERING, `${direction}${String(sortField)}`)
      } else {
        deleteParam(PARAMS.ORDERING)
        setParam(PARAMS.ORDER_BY, `${direction}${String(sortField)}`)
      }

      setSortField(sortField)
      setSortDirection(sortDirection)
    }
  }

  return {
    sorting,
    handleTableChange,
  }
}

export { useSkuTableSort }
