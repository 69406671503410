import { EuiFieldText, EuiFormRow } from '@elastic/eui'
import { isFieldError } from '@shared/lib'
import { useFormContext } from 'react-hook-form'

import { type FindErrorMessageArgs, type InnerError, type TextInputProps } from './types'

const getError = ({ errors, name }: FindErrorMessageArgs) => {
  const names = name.split('.')
  const typedErrors = errors as Record<string, InnerError>

  return typedErrors?.[names[0]]?.[names[1]]
}

export const TextInput: ReactFc<TextInputProps> = ({
  name,
  icon,
  placeholder,
  registerOptions,
  inputProps,
  hasLabel = true,
  shouldDispalayError = true,
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext()

  const { onBlur, onChange, ref } = register(name, registerOptions)

  const error = name.includes('.') ? getError({ errors, name }) : errors?.[name]

  return (
    <EuiFormRow
      //TODO: hasLabel убрать, добавить возможность передавать label через props
      label={hasLabel ? placeholder : ''}
      isInvalid={!!error}
      error={shouldDispalayError && isFieldError(error) && error?.message}
    >
      <EuiFieldText
        icon={icon}
        placeholder={placeholder}
        isInvalid={!!error}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        inputRef={ref}
        {...inputProps}
      />
    </EuiFormRow>
  )
}
