import { useCatalogListApi } from '@entities/catalog'
import { useCategoriesListApi } from '@entities/categories'
import { convertToComboOption, convertToOption } from '@shared/lib'

const useCategoryForm = (id?: number) => {
  // Список каталогов
  const { isLoading: isCatalogOptionsLoading, data: catalogs } = useCatalogListApi()
  const catalogOptions = catalogs?.map(convertToComboOption)

  // Список категорий для выпадающего списка
  const { isLoading: isCategoryOptionsLoading, data: categories } = useCategoriesListApi()

  const categoryOptions =
    categories?.results
      .filter((category) => {
        return category.id !== id
      })
      .map(convertToOption) || []

  return {
    isCatalogOptionsLoading,
    catalogOptions,
    isCategoryOptionsLoading,
    categoryOptions,
  }
}

export { useCategoryForm }
