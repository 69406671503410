import { type AttributeGroup } from '@entities/attributes/types'
import { MenuItemRemoveAttributeGroup, UpdateAttributeGroupModal } from '@features/attributes'
import { ACTION } from '@shared/config'
import { useDialog } from '@shared/hooks'
import { MenuItemDialog, TableContextMenu } from '@shared/ui/context-menu'

const GroupsContextMenu = (props: AttributeGroup) => {
  const { isOpen, handleOpenDialog: showModal, handleCloseDialog: closeModal } = useDialog()

  return (
    <>
      <TableContextMenu>
        <MenuItemDialog onClick={showModal}>{ACTION.EDIT}</MenuItemDialog>
        <MenuItemRemoveAttributeGroup id={props.id} />
      </TableContextMenu>

      <UpdateAttributeGroupModal id={props.id} isOpen={isOpen} closeModal={closeModal} />
    </>
  )
}

export { GroupsContextMenu }
