import { EuiFlexGroup, EuiFlexItem, EuiForm } from '@elastic/eui'
import { useImportTypesListApi } from '@entities/import-files'
import { ACTION, MESSAGE } from '@shared/config'
import { CheckboxInput, FilePicker, FormError, SelectInput } from '@shared/ui/form'
import { Modal } from '@shared/ui/modal'
import { FormProvider } from 'react-hook-form'

import { useAddImportFile } from '../model'
import { type AddImportFilesProps } from '../types'

const AddImportFile: ReactFc<AddImportFilesProps> = ({ isOpen, onClose }) => {
  const { data: importTypes } = useImportTypesListApi()

  const { handleModalClose, methods, handleAddUser, isSubmitting } = useAddImportFile({ onClose })

  // TODO: воспользоваться функцией для создания опций после слива изменений по автоматизации
  const importTypeOptions =
    importTypes?.map(({ value, name }) => {
      return {
        value,
        inputDisplay: name,
      }
    }) || []

  return (
    <FormProvider {...methods}>
      <Modal
        title={ACTION.NEW_IMPORT_FILE}
        onClose={handleModalClose}
        onSubmit={handleAddUser}
        isOpen={isOpen}
        isSubmitting={isSubmitting}
      >
        <EuiForm component="form">
          <EuiFlexGroup direction="column" gutterSize="none">
            <SelectInput
              name="import_type"
              label={MESSAGE.IMPORT_TYPE}
              inputProps={{ compressed: true, options: importTypeOptions, disabled: isSubmitting }}
              registerOptions={{ required: true }}
            />
            <CheckboxInput
              name="start_now"
              inputProps={{ label: ACTION.START_AFTER_VALIDATION, disabled: isSubmitting }}
            />
            <FilePicker
              name="file_upload"
              placeholder={MESSAGE.FILE_UPLOAD}
              registerOptions={{ required: true, disabled: isSubmitting }}
            />
            <EuiFlexItem>
              <FormError />
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiForm>
      </Modal>
    </FormProvider>
  )
}

export { AddImportFile }
